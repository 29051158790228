import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  ListItem,
  List,
  useMediaQuery,
} from "@chakra-ui/react";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { SidebarLink } from "types/sidebar";
import useStore from "store/store";

interface SidebarProps {
  title: string;
  links: any;
}

const Sidebar = ({ title, links }: SidebarProps) => {
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");
  const [isMounted, setIsMounted] = useState(false);
  const [open, setOpen] = useState<boolean>(true);
  const navigate = useNavigate();
  const { selectedTab, setSelectedTab } = useStore();

  // Set component as mounted after it loads
  useEffect(() => {
    setIsMounted(true);
  }, []);

  // Set sidebar state based on media query only after component mounts
  useEffect(() => {
    if (isMounted) {
      setOpen(isLargerThan600);
    }
  }, [isLargerThan600, isMounted]);

  return (
    <Flex>
      <Box
        w={open ? "72" : "20"}
        bg="#32312f"
        h="100%"
        p={open ? "20px" : "12px"}
        pt="8"
        position="relative"
        transition="width 0.3s"
      >
        <IconButton
          icon={open ? <IconChevronLeft /> : <IconChevronRight />}
          position="absolute"
          top="9"
          right="-3"
          size="sm"
          bg={"#c88a5d"}
          borderRadius="full"
          onClick={() => setOpen(!open)}
          aria-label="Toggle Menu"
        />
        <Flex align="center" gap="4">
          <Text
            color="white"
            fontSize="xl"
            fontWeight="medium"
            style={{ transition: "transform 0.2s", transformOrigin: "left" }}
            transform={!open ? "scale(0)" : "scale(1)"}
          >
            {title}
          </Text>
        </Flex>

        <List pt="6" spacing="2">
          {links.map((item: SidebarLink, index: number) => (
            <ListItem
              key={index}
              display="flex"
              alignItems="center"
              justifyContent={open ? "start" : "center"}
              gap="4"
              p={open ? "12px 16px" : "12px"}
              borderRadius="md"
              cursor="pointer"
              bg={selectedTab === index ? "#c88a5d" : "transparent"}
              _hover={{ bg: "#c88a5d80" }}
              mt={item.gap ? "9" : "2"}
              color={selectedTab === index ? "#000" : "gray.300"}
              fontSize="sm"
              onClick={() => {
                setSelectedTab(index);
                if (!isLargerThan600) {
                  setOpen(false);
                }
                navigate(item.path);
              }}
            >
              {item.icon}
              <Text
                display={!open ? "none" : "block"}
                transition="opacity 0.2s"
              >
                {item.title}
              </Text>
            </ListItem>
          ))}
        </List>
      </Box>
    </Flex>
  );
};

export default Sidebar;
