import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";

const Logo = () => {
  const navigate = useNavigate();
  return (
    <img
      style={{ cursor: "pointer" }}
      src={logo}
      alt="Logo"
      width="400"
      onClick={() => navigate("/")}
    />
  );
};

export default Logo;
