import { useState } from "react";
import {
  Box,
  Button,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { getGenerateCientDocument } from "services/api/document";
import useStore from "store/store";
import { IconClipboardOff, IconFileCheck } from "@tabler/icons-react";
import { IconCircleCheck } from "@tabler/icons-react";
import { updateFormStatuses } from "services/api/form";
import { User } from "types/user";

const CompleteForm = () => {
  const [loading, setLoading] = useState(false);
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");
  const toast = useToast();

  const { forms, applicationIsComplete, setApplicationIsComplete } = useStore();

  const user = useStore((state) => state.user) as User;

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await getGenerateCientDocument(user?.userId!, user?.email!);
      await updateFormStatuses({
        email: user?.email!,
        application_status: "complete",
        clientFormStatus: "complete",
        spouseFormStatus: "complete",
        marriageFormStatus: "complete",
        familyFormStatus: "complete",
        affidavitFormStatus: "complete",
      });
      toast({
        title: "Forms submitted.",
        description: "You've successfully submitted the forms.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
      setLoading(false);
      setApplicationIsComplete();
    } catch (err) {
      setLoading(false);
      toast({
        title: "Error submitting forms.",
        description: "An error occurred while submitting the forms.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
      console.error("Error: ", err);
    }
  };

  const {
    clientFormStatus,
    spouseFormStatus,
    marriageFormStatus,
    familyFormStatus,
    affidavitFormStatus,
  } = user || {};

  const statuses = [
    clientFormStatus,
    spouseFormStatus,
    marriageFormStatus,
    familyFormStatus,
    affidavitFormStatus,
  ];

  if (applicationIsComplete || user?.application_status === "complete") {
    return (
      <Box
        h={"100%"}
        w={"100%"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <IconCircleCheck
          size={100}
          color={"green"}
          stroke={1}
          style={{ marginBottom: "16px" }}
        />
        <Text pb={4}>Thank you for completing our forms</Text>
        <Text>We will be in touch with the next steps</Text>
      </Box>
    );
  }

  return (
    <>
      <TableContainer sx={{ padding: "32px 0px", overflow: "hidden" }}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Form</Th>
              <Th sx={{ textAlign: "right" }}>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {forms.map((form, index) => {
              return (
                <Tr key={index}>
                  <Td sx={{ fontSize: isLargerThan600 ? "14px" : "12px" }}>
                    {form.name}
                  </Td>
                  {statuses[index] === "complete" && (
                    <Td>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: isLargerThan600 ? "end" : "center",
                        }}
                      >
                        <IconFileCheck size={20} color="#37ba32" />
                        {isLargerThan600 && (
                          <Text sx={{ pl: 2 }}>Complete</Text>
                        )}
                      </Box>
                    </Td>
                  )}
                  {statuses[index] !== "complete" && (
                    <Td>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: isLargerThan600 ? "end" : "center",
                        }}
                      >
                        <IconClipboardOff size={20} color="#e93e3e" />
                        {isLargerThan600 && (
                          <Text sx={{ pl: 2 }}>Not Complete</Text>
                        )}
                      </Box>
                    </Td>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Box sx={{ py: "16px" }}>
        <Text sx={{ fontSize: "14px", fontWeight: "bold" }}>
          Please complete all forms before submitting them.
        </Text>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Button
          isDisabled={
            loading ||
            user?.application_status ===
              ("complete" as "inProgress" | "complete")
          }
          bg="#c88a5d"
          color={"#fff"}
          sx={{ width: "100%" }}
          onClick={handleSubmit}
        >
          {loading ? (
            <Spinner
              thickness="1px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#c88a5d"
              size="md"
            />
          ) : (
            <Text>Submit Forms</Text>
          )}
        </Button>
      </Box>
    </>
  );
};

export default CompleteForm;
