import { ADMIN_API_URL } from "config";
import httpClient from "./httpClient";

export function getGenerateCientDocument(
  clientId: string,
  email: string
): Promise<any> {
  const body = { primary_key: clientId, email: email };
  return httpClient
    .post(`${ADMIN_API_URL}/generate-document`, body)
    .then((resp) => {
      return resp.data ?? {};
    });
}
