import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import useStore from "store/store";

interface ConfirmDeleteModalProps {
  onDelete: () => Promise<void>;
}

const ConfirmDeleteModal = ({ onDelete }: ConfirmDeleteModalProps) => {
  const { isDeleteModalOpen, closeDeleteModal } = useStore();

  const handleConfirm = () => {
    onDelete();
    closeDeleteModal();
  };

  return (
    <Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal} isCentered>
      <ModalOverlay />
      <ModalContent sx={{ maxWidth: "80vw" }}>
        <ModalHeader>Confirm Delete</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Are you sure you want to delete this user?</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={closeDeleteModal}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleConfirm}>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmDeleteModal;
