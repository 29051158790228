import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import useStore from "store/store";
import { IconHome, IconFiles, IconUserShield } from "@tabler/icons-react";

const links = [
  { name: "Home", path: "/", icon: <IconHome size={18} /> },
  { name: "Forms", path: "/forms", icon: <IconFiles size={18} /> },
  { name: "Admin", path: "/dashboard", icon: <IconUserShield size={18} /> },
];

const SideMenu = () => {
  const { isSidebarOpen, closeSidebar } = useStore();
  const navigate = useNavigate();

  const handleClick = (path: string) => {
    navigate(path);
    closeSidebar();
  };

  return (
    <>
      <Drawer isOpen={isSidebarOpen} placement="right" onClose={closeSidebar}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody>
            <Box sx={{ display: "flex", flexDirection: "column", pt: "100px" }}>
              {links.map((link, index) => {
                return (
                  <Button
                    key={index}
                    sx={{
                      color: "#000",
                      mb: 4,
                      p: "24px",
                    }}
                    onClick={() => handleClick(link.path)}
                  >
                    {link.icon}
                    <Text sx={{ fontSize: "16px", fontWeight: 400, pl: 3 }}>
                      {link.name}
                    </Text>
                  </Button>
                );
              })}
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SideMenu;
