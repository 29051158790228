import {
  Box,
  Button,
  Divider,
  FormLabel,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useFieldArray, useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import "./form.styles.css";
import { useEffect, useState } from "react";
import ChildInput from "../../components/inputs/ChildInput";
import ProceedingInput from "../../components/inputs/ProceedingInput";
import { scrollToTop } from "utils/ScrollToTop";
import useStore from "store/store";
import {
  createMarriageFormData,
  fetchAndSetFormData,
  getMarriageFormData,
} from "services/api/form";
import ConfirmSubmitModal from "components/modals/ConfirmSubmitModal";
import FormInput from "components/inputs/FormInput";
import { IconFileCheck } from "@tabler/icons-react";
import { MarriageForm as MarriageInterface } from "types/form";

export default function MarrageForm() {
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const {
    openSubmitModal,
    updateForm,
    selectedMarriageForm,
    setSelectedMarriageForm,
    user,
    setApplicationIsComplete,
    forms,
    addCompletedForm,
    formsCompleted,
    setUser,
  } = useStore();

  const {
    control,
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<MarriageInterface>({ defaultValues: selectedMarriageForm });

  const {
    fields: childrenFields,
    append: appendChild,
    remove: removeChild,
  } = useFieldArray({
    control,
    name: "marriageChildrenDetails",
  });

  const {
    fields: nonMarriageChildrenFields,
    append: appendNonMarriageChild,
    remove: removeNonMarriageChild,
  } = useFieldArray({
    control,
    name: "marriageNonMarriageChildrenDetails",
  });

  const {
    fields: proceedingsFields,
    append: appendProceeding,
    remove: removeProceeding,
  } = useFieldArray({
    control,
    name: "marriageProceedingsDetails",
  });

  const isComplete = forms.find((form) => form.id === 3)?.isComplete;

  // Reset form when selectedClientForm changes
  useEffect(() => {
    if (selectedMarriageForm) {
      reset(selectedMarriageForm);
    }
  }, [selectedMarriageForm, reset]);

  useEffect(() => {
    console.info("rendered");

    const fetchMarriageFormData = async () => {
      if (user?.marriageFormStatus === "saved") {
        try {
          setLoading(true); // Optional: manage loading state
          await fetchAndSetFormData(
            setSelectedMarriageForm,
            getMarriageFormData,
            user!
          );
          setLoading(false); // Optional: reset loading state after fetching data
        } catch (error) {
          setLoading(false); // Optional: reset loading state in case of error
          console.error("Failed to fetch marriage form data:", error);
        }
      }
    };

    fetchMarriageFormData();
  }, [setSelectedMarriageForm, user]);

  const handleSaveWithoutValidation = () => {
    const data = getValues();
    onSaveForm(data);
  };

  const onSaveForm = async (data: any) => {
    const { submission_id, submitted_at, message, ...rest } = data;
    const body = { ...rest, clientId: user?.userId };

    try {
      setSaving(true);
      const marriageData = await createMarriageFormData(body);

      setUser({ ...user!, isLoggedIn: true, marriageFormStatus: "saved" });
      setSelectedMarriageForm(marriageData);
      toast({
        title: "Saved form.",
        description: "You've successfully saved this information.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
      setSaving(false);
    } catch (err: any) {
      console.error("Failed to fetch client data:", err);
      setSaving(false);
      toast({
        title: "Error saving form.",
        description: "An error occurred while saving this form.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };

  const onSubmitForm = (data: any) => {
    handleSubmit(() => {
      if (Object.keys(errors).length === 0) {
        openSubmitModal();
      } else {
        scrollToTop();
      }
    })(data);
  };

  const onFormConfirm = async (data: any) => {
    const { submission_id, submitted_at, ...rest } = data;
    const body = { ...rest, clientId: user?.userId };

    try {
      setLoading(true);
      const marriageData = await createMarriageFormData(body);

      setUser({
        ...user!,
        isLoggedIn: true,
        marriageFormStatus: "complete",
      });
      setSelectedMarriageForm(marriageData);
      updateForm({ id: 3, isComplete: true });
      toast({
        title: "Saved form.",
        description: "You've successfully completed this form.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });
      setLoading(false);
      addCompletedForm();
      scrollToTop();
      if (formsCompleted >= 5) {
        setApplicationIsComplete();
      }
    } catch (err: any) {
      console.error("Failed to fetch client data:", err);
      setLoading(false);
      toast({
        title: "Error saving form.",
        description: "An error occurred while completing this form.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };

  const formLabelStyle = {
    fontWeight: 400,
  };

  if (loading) {
    return (
      <Box
        sx={{
          w: "100%",
          h: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="#c88a5d"
          size="xl"
        />
      </Box>
    );
  }

  if (
    isComplete ||
    user?.application_status === "complete" ||
    user?.marriageFormStatus === "complete"
  )
    return (
      <Box sx={{ display: "flex", flexDirection: "column", color: "#404040" }}>
        <Box sx={{ padding: "32px 0" }}>
          <Text sx={{ fontWeight: "bold", fontSize: "20px" }}>
            MARRIAGE INFORMATION
          </Text>
        </Box>
        <Box sx={{ display: "flex" }}>
          <IconFileCheck size={22} color="#37ba32" />
          <Text sx={{ ml: 2 }}>The Marriage Information form is complete</Text>
        </Box>
      </Box>
    );

  return (
    <>
      <form>
        <Box
          sx={{ display: "flex", flexDirection: "column", color: "#404040" }}
        >
          <Box sx={{ padding: "32px 0" }}>
            <Text sx={{ fontWeight: "bold", fontSize: "20px" }}>
              MARRIAGE INFORMATION
            </Text>
          </Box>

          <>
            <Box>
              <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>
                PLEASE ADD ANY CHILDREN OF THE MARRIAGE
              </Text>
              {childrenFields.map((child, index) => (
                <Box key={child.id}>
                  <ChildInput
                    control={control}
                    register={register}
                    childIndex={index}
                    errors={errors}
                    id={`marriageChildrenDetails.${index}`}
                    title="Marriage Child"
                  />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {childrenFields.length >= 1 && (
                      <Button
                        onClick={() => removeChild(index)}
                        type="button"
                        variant={"outlined"}
                        border="2px solid #ff4141"
                        color={"#ff4141"}
                      >
                        Remove
                      </Button>
                    )}
                  </Box>
                </Box>
              ))}
              <Button
                mt={2}
                onClick={() => appendChild({ name: "", dob: "", schools: "" })}
                type="button"
                bg="#c88a5d"
                color={"#fff"}
              >
                Add child
              </Button>
            </Box>

            <Divider sx={{ margin: "32px 0", border: " 1px solid #e8e8e8" }} />

            <Box>
              <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>
                PLEASE ADD ANY CHILDREN NOT OF THE MARRIAGE
              </Text>
              {nonMarriageChildrenFields.map((child, index) => (
                <Box key={index}>
                  <ChildInput
                    control={control}
                    register={register}
                    childIndex={index}
                    errors={errors}
                    id={`marriageNonMarriageChildrenDetails.${index}`}
                    title="Non marriage child"
                  />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {/* Show the Remove button only if there are more than one children */}
                    {nonMarriageChildrenFields.length >= 1 && (
                      <Button
                        onClick={() => removeNonMarriageChild(index)}
                        type="button"
                        border="2px solid #ff4141"
                        color={"#ff4141"}
                        variant={"outlined"}
                      >
                        Remove
                      </Button>
                    )}
                  </Box>
                </Box>
              ))}

              <Button
                onClick={() =>
                  appendNonMarriageChild({ name: "", dob: "", schools: "" })
                }
                type="button"
                bg="#c88a5d"
                color={"#fff"}
                mt={2}
              >
                Add child
              </Button>

              <Divider
                sx={{ margin: "32px 0", border: " 1px solid #e8e8e8" }}
              />
            </Box>

            <Box>
              <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>
                MARRIAGE & HISTORY
              </Text>

              <FormInput
                id={"marriagePlaceOfMarriage"}
                label={"PLACE OF MARRIAGE (i.e., exact Church name & address)"}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />

              <FormInput
                id={"marriageDateOfMarriage"}
                label={"DATE OF MARRIAGE"}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"date"}
                errors={errors}
              />

              <FormInput
                id={"marriageEitherSpouseDivorced"}
                label={"WERE EITHER SPOUSE PREVIOUSLY DIVORCED"}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />

              <FormInput
                id={"marriageDivorceDetails"}
                label={"DIVORCED DETAILS (Whom, when & where)"}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />

              <FormInput
                id={"marriageDateCommencedLivingApart"}
                label={"DATE COMMENCED LIVING APART?"}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"date"}
                errors={errors}
              />

              <Box
                sx={{
                  padding: "8px 0",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <FormLabel
                  style={formLabelStyle}
                  htmlFor="marriageDeedOfSeparation"
                >
                  DID PARTIES EXECUTE A DEED OF SEPARATION
                </FormLabel>
                <Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", pb: "8px" }}
                  >
                    <input
                      {...register("marriageDeedOfSeparation", {
                        required: false,
                      })}
                      type="radio"
                      value="Yes"
                    />
                    <Text sx={{ paddingLeft: 2 }}>Yes</Text>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <input
                      {...register("marriageDeedOfSeparation", {
                        required: false,
                      })}
                      type="radio"
                      value="No"
                    />
                    <Text sx={{ paddingLeft: 2 }}>No</Text>
                  </Box>
                </Box>
              </Box>

              <FormInput
                id={"marriageRelationshipPriorToMarriage"}
                label={"RELATIONSHIP PRIOR TO MARRIAGE – Provide Details"}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                isTextarea
                errors={errors}
              />

              <FormInput
                id={"marriageRelationshipAfterMarriage"}
                label={`RELATIONSHIP AFTER MARRIAGE – Provide Details of any issues, etc.`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                isTextarea
                type={"text"}
                errors={errors}
              />

              <FormInput
                id={"marriageReasonForMarriageBreakdown"}
                label={`REASON FOR MARRIAGE BREAKDOWN`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                isTextarea
                type={"text"}
                errors={errors}
              />
            </Box>

            <Divider sx={{ margin: "32px 0", border: " 1px solid #e8e8e8" }} />

            <Box>
              <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>
                COURT PROCEEDINGS HISTORY
              </Text>
              {proceedingsFields.map((child, index) => (
                <Box key={index}>
                  <ProceedingInput
                    register={register}
                    childIndex={index}
                    errors={errors}
                    id={`marriageProceedingsDetails.${index}`}
                    title="Proceedings Details"
                  />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {/* Show the Remove button only if there are more than one children */}
                    {proceedingsFields.length >= 1 && (
                      <Button
                        onClick={() => removeProceeding(index)}
                        type="button"
                        border="2px solid #ff4141"
                        color={"#ff4141"}
                        variant={"outlined"}
                      >
                        Remove Proceeding
                      </Button>
                    )}
                  </Box>
                </Box>
              ))}
              <Button
                onClick={() =>
                  appendProceeding({
                    court: "",
                    matter: "",
                    date: "",
                    outcome: "",
                  })
                }
                type="button"
                bg="#c88a5d"
                color={"#fff"}
                mt={2}
              >
                Add Proceeding
              </Button>
            </Box>

            <Divider sx={{ margin: "32px 0", border: " 1px solid #e8e8e8" }} />

            <Box
              sx={{
                padding: "8px 0",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormLabel
                style={formLabelStyle}
                htmlFor="marriageCopyOrderProvidedByClient"
              >
                COPY ORDER PROVIDED BY CLIENT
              </FormLabel>
              <Box>
                <Box sx={{ display: "flex", alignItems: "center", pb: "8px" }}>
                  <input
                    {...register("marriageCopyOrderProvidedByClient", {
                      required: false,
                    })}
                    type="radio"
                    value="Yes"
                  />
                  <Text sx={{ paddingLeft: 2 }}>Yes</Text>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <input
                    {...register("marriageCopyOrderProvidedByClient", {
                      required: false,
                    })}
                    type="radio"
                    value="No"
                  />
                  <Text sx={{ paddingLeft: 2 }}>No</Text>
                </Box>
              </Box>
            </Box>

            <FormInput
              id={"marriageAnyMarriageCounselling"}
              label={`ANY MARRIAGE COUNSELLING? If so, provide Name & Address of the
                  organisation and how many sessions were attended.`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              isTextarea
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"marriageAnyMarriageMediation"}
              label={`ANY MARRIAGE MEDIATION? If so, provide Name & Address of the
                  organisation and how many sessions were attended.`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              isTextarea
              type={"text"}
              errors={errors}
            />

            <Button
              onClick={handleSaveWithoutValidation}
              border="2px solid #c88a5d"
              color={"#c88a5d"}
              variant="outline"
              isDisabled={saving}
              mt={4}
            >
              {saving ? (
                <Spinner
                  thickness="1px"
                  speed="0.65s"
                  emptyColor="#fff"
                  color="#c88a5d"
                  size="md"
                />
              ) : (
                <Text>Save Marriage information for later</Text>
              )}
            </Button>
            <Button onClick={onSubmitForm} bg="#c88a5d" color={"#fff"} mt={4}>
              Complete Marriage information
            </Button>
          </>
        </Box>
      </form>
      <ConfirmSubmitModal onSubmit={handleSubmit(onFormConfirm)} />
    </>
  );
}
