import { Box, Button, Spinner, Text, useMediaQuery } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAffidavitFormData,
  getClientFormData,
  getFamilyFormData,
  getMarriageFormData,
  getSpouseFormData,
} from "services/api/form";
import useStore from "store/store";
import {
  ClientForm,
  // FamilyForm,
  MarriageForm,
  MarriageChildDetails,
  MarriageProceedingDetails,
  SpouseForm,
  AffidavitForm,
  FamilyForm,
  AffiChildDetails,
  FinancialAccount,
} from "types/form";

const ClientDetails = () => {
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const { userId } = location.state;

  const {
    setSelectedClientForm,
    setSelectedSpouseForm,
    setSelectedMarriageForm,
    setSelectedFamilyForm,
    setSelectedAffidavitForm,
    clearSelectedClientForm,
    clearSelectedSpouseForm,
    clearSelectedMarriageForm,
    clearSelectedFamilyForm,
    clearSelectedAffidavitForm,
    selectedClientForm,
    selectedSpouseForm,
    selectedMarriageForm,
    selectedFamilyForm,
    selectedAffidavitForm,
  } = useStore();

  useEffect(() => {
    async function fetchAndSetClientFormData() {
      try {
        setLoading(true);
        clearSelectedClientForm();
        clearSelectedSpouseForm();
        clearSelectedMarriageForm();
        clearSelectedFamilyForm();
        clearSelectedAffidavitForm();
        const clientData = await getClientFormData(userId);
        setSelectedClientForm(clientData);
        const spouseData = await getSpouseFormData(userId);
        setSelectedSpouseForm(spouseData);
        const marriageData = await getMarriageFormData(userId);
        setSelectedMarriageForm(marriageData);
        const familyData = await getFamilyFormData(userId);
        setSelectedFamilyForm(familyData);
        const affidavitData = await getAffidavitFormData(userId);
        setSelectedAffidavitForm(affidavitData);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch client data:", error);
        setLoading(false);
      }
    }
    fetchAndSetClientFormData();
  }, [
    setSelectedClientForm,
    setSelectedSpouseForm,
    setSelectedMarriageForm,
    setSelectedFamilyForm,
    setSelectedAffidavitForm,
    clearSelectedClientForm,
    clearSelectedSpouseForm,
    clearSelectedMarriageForm,
    clearSelectedFamilyForm,
    clearSelectedAffidavitForm,
    userId,
  ]);

  if (loading) {
    return (
      <Box
        sx={{
          w: "100vw",
          h: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="#c88a5d"
          size="xl"
        />
      </Box>
    );
  }

  const clientForm = selectedClientForm as ClientForm;
  const spouseForm = selectedSpouseForm as SpouseForm;
  const marriageForm = selectedMarriageForm as MarriageForm;
  const familyForm = selectedFamilyForm as FamilyForm;
  const affidavitForm = selectedAffidavitForm as AffidavitForm;

  const noFormData =
    Object.keys(selectedClientForm).length === 0 &&
    Object.keys(selectedSpouseForm).length === 0 &&
    Object.keys(selectedMarriageForm).length === 0 &&
    Object.keys(selectedFamilyForm).length === 0 &&
    Object.keys(selectedAffidavitForm).length === 0;

  const clientDetails: Array<{ label: string; value: keyof ClientForm }> = [
    { label: "First Name", value: "clientFirstName" },
    { label: "Last Name", value: "clientLastName" },
    { label: "Email", value: "clientEmail" },
    { label: "Date of Birth", value: "clientDob" },
    { label: "Phone", value: "clientPhone" },
    { label: "Address", value: "clientAddress" },
    { label: "Nationality", value: "clientNationality" },
    { label: "Employment Status", value: "clientEmploymentStatus" },
    { label: "Current Employer", value: "clientCurrentEmployer" },
    { label: "Unemployment Duration", value: "clientUnemploymentDuration" },
    { label: "Pension Scheme", value: "clientPensionScheme" },
    { label: "Salary", value: "clientSalary" },
    { label: "Skills", value: "clientSkills" },
    { label: "Occupants", value: "clientOccupants" },
    { label: "Working Days", value: "clientWorkingDays" },
  ];

  const spouseDetails: Array<{ label: string; value: keyof SpouseForm }> = [
    { label: "Name", value: "spouseFirstName" },
    { label: "Last Name", value: "spouseLastName" },
    { label: "Email", value: "spouseEmail" },
    { label: "Date of Birth", value: "spouseDob" },
    { label: "Phone", value: "spousePhone" },
    { label: "Address", value: "spouseAddress" },
    { label: "Nationality", value: "spouseNationality" },
    { label: "Employment Status", value: "spouseEmploymentStatus" },
    { label: "Current Employer", value: "spouseCurrentEmployer" },
    { label: "Unemployment Duration", value: "spouseUnemploymentDuration" },
    { label: "Pension Scheme", value: "spousePensionScheme" },
    { label: "Salary", value: "spouseSalary" },
    { label: "Skills", value: "spouseSkills" },
    { label: "Occupants", value: "spouseOccupants" },
    { label: "Working Days", value: "spouseWorkingDays" },
    { label: "Solicitors Address", value: "spouseSolicitorAddress" },
  ];

  const marriageDetails: Array<{ label: string; value: keyof MarriageForm }> = [
    { label: "Place Of Marriage", value: "marriagePlaceOfMarriage" },
    { label: "Date Of Marriage", value: "marriageDateOfMarriage" },
    {
      label: "Either Spouse Previously Divorced",
      value: "marriageEitherSpouseDivorced",
    },
    { label: "Divorce Details", value: "marriageDivorceDetails" },
    {
      label: "Date Commenced Living Apart",
      value: "marriageDateCommencedLivingApart",
    },
    { label: "Deed Of Separation", value: "marriageDeedOfSeparation" },
    {
      label: "Relationshih Prior To Marriage",
      value: "marriageRelationshipPriorToMarriage",
    },
    {
      label: "Relationshih After Marriage",
      value: "marriageRelationshipAfterMarriage",
    },
    {
      label: "Reason For Marriage Breakdown",
      value: "marriageReasonForMarriageBreakdown",
    },
    {
      label: "Copy Order Provided By Client",
      value: "marriageCopyOrderProvidedByClient",
    },
    {
      label: "Any Marriage Counselling",
      value: "marriageAnyMarriageCounselling",
    },
    { label: "Any Marriage Mediation", value: "marriageAnyMarriageMediation" },
  ];

  const familyDetails: Array<{ label: string; value: keyof FamilyForm }> = [
    { label: "Address", value: "familyAddress" },
    { label: "Date Of Purchase", value: "familyDateOfPurchase" },
    { label: "Purchase Price", value: "familyPurchasePrice" },
    { label: "Deposit Amount", value: "familyDepositAmount" },
    {
      label: "Contridbutions By Husband",
      value: "familyDepositContributionsByHusband",
    },
    {
      label: "Contridbutions By Wife",
      value: "familyDepositContributionsByWife",
    },
    { label: "Previous Property Proceeds", value: "familyPrevPropProceeds" },
    { label: "Present Value", value: "familyPresentValue" },
    { label: "Mortgage", value: "familyMortgageYn" },
    { label: "Topup Details", value: "familyTopupDetails" },
    { label: "Bank Details", value: "familyBankDetails" },
    { label: "Monthly Mortgage", value: "familyMonthlyMortgage" },
    { label: "Mortgage Payer", value: "familyMortgagePayer" },
    { label: "Arrears Amount", value: "familyArrearsAmount" },
    { label: "Mortgage Due", value: "familyMortgageDue" },

    {
      label: "Additional Home Property Address",
      value: "familyAddHomePropertyAddress",
    },
    {
      label: "Additional Home Property Type",
      value: "familyAddHomePropertyType",
    },
    {
      label: "Additional Home Purchase Price",
      value: "familyAddHomePurchasePrice",
    },
    {
      label: "Additional Home Deposit Amount",
      value: "familyAddHomeDepositAmount",
    },
    {
      label: "Additional Home Husband Contribution",
      value: "familyAddHomeHusbandContribution",
    },
    {
      label: "Additional Home Wife Contribution",
      value: "familyAddHomeWifeContribution",
    },
    {
      label: "Additional Home Present Value",
      value: "familyAddHomePresentValue",
    },
    { label: "Additional Home MortgageYn", value: "familyAddHomeMortgageYn" },
    { label: "Additional Home Bank", value: "familyAddHomeBankAuthority" },
    {
      label: "Additional Home Interest Only",
      value: "familyAddHomeInterestOnly",
    },
    {
      label: "Additional Home Arrears Amount",
      value: "familyAddHomeArrearsAmount",
    },
    {
      label: "Additional Home Mortgage Due",
      value: "familyAddHomeMortgageDue",
    },
    {
      label: "Additional Home Court Proceedings",
      value: "familyAddHomeCourtProceedings",
    },

    { label: "House Seeking", value: "familyHouseSeeking" },
    {
      label: "Financial Settlement Seeking",
      value: "familyFinancialSettlementSeeking",
    },
    { label: "Custody Seeking", value: "familyCustodySeeking" },
    { label: "Access Seeking", value: "familyAccessSeeking" },
    { label: "ChildMaint Seeking", value: "familyChildMaintSeeking" },
    { label: "Spouse Maintenance Seeking", value: "familySpouseMaintSeeking" },
  ];

  const affidavitDetails: Array<{ label: string; value: keyof AffidavitForm }> =
    [
      { label: "Affidavit Address", value: "affiAddress" },
      { label: "Affidavit Age", value: "affiAge" },
      {
        label: "Applicant Respondent Health",
        value: "affiApplicantRespondentHealth",
      },
      { label: "Cable Television", value: "affiCableTelevision" },
      { label: "Car Market Value", value: "affiCarMarketValue" },
      { label: "Car Registration Type", value: "affiCarRegistrationType" },
      { label: "Changes Details", value: "affiChangesDetails" },
      { label: "Changes In Childcare", value: "affiChangesInChildcare" },
      { label: "Changes In Education", value: "affiChangesInEducation" },
      { label: "Changes To Contact", value: "affiChangesToContact" },
      { label: "Charges AA Membership", value: "affiChargesAAMembership" },
      {
        label: "Charges Adult Educational Classes",
        value: "affiChargesAdultEducationalClasses",
      },
      { label: "Charges Car Insurance", value: "affiChargesCarInsurance" },
      { label: "Charges Car Maintenance", value: "affiChargesCarMaintenance" },
      { label: "Charges Car Tax", value: "affiChargesCarTax" },
      {
        label: "Charges Children's Clothing",
        value: "affiChargesChildrensClothing",
      },
      {
        label: "Charges Clothing And Shoes (Self)",
        value: "affiChargesClothingAndShoesSelf",
      },
      {
        label: "Charges Creche/Babysitter/Childminder",
        value: "affiChargesCrecheBabysitterChildminder",
      },
      {
        label: "Charges Credit Card Payments",
        value: "affiChargesCreditCardPayments",
      },
      { label: "Charges Dentist", value: "affiChargesDentist" },
      { label: "Charges Entertainment", value: "affiChargesEntertainment" },
      { label: "Charges Gardening", value: "affiChargesGardening" },
      {
        label: "Charges Gifts (Birthdays/Christmas)",
        value: "affiChargesGiftsBirthdaysChristmas",
      },
      {
        label: "Charges Hairdresser (Self/Children)",
        value: "affiChargesHairdresserSelfChildren",
      },
      {
        label: "Charges Health Insurance",
        value: "affiChargesHealthInsurance",
      },
      {
        label: "Charges Hire Purchase Repayments",
        value: "affiChargesHirePurchaseRepayments",
      },
      { label: "Charges Hobbies", value: "affiChargesHobbies" },
      {
        label: "Charges Holidays/Day Outings",
        value: "affiChargesHolidaysDayOutings",
      },
      { label: "Charges Life Assurance", value: "affiChargesLifeAssurance" },
      { label: "Charges Loan Repayments", value: "affiChargesLoanRepayments" },
      {
        label: "Charges Medical Expense (Not Covered)",
        value: "affiChargesMedicalExpenseNotCovered",
      },
      {
        label: "Charges Membership Fees/Clubs",
        value: "affiChargesMembershipFeesClubs",
      },
      { label: "Charges Miscellaneous", value: "affiChargesMiscellaneous" },
      { label: "Charges Mobile Phone", value: "affiChargesMobilePhone" },
      { label: "Charges Other", value: "affiChargesOther" },
      { label: "Charges Petrol", value: "affiChargesPetrol" },
      {
        label: "Charges Pharmacy Expenses",
        value: "affiChargesPharmacyExpenses",
      },
      {
        label: "Charges Pocket Money/Presents",
        value: "affiChargesPocketMoneyPresents",
      },
      {
        label: "Charges Public Transport (Self)",
        value: "affiChargesPublicTransportSelf",
      },
      { label: "Charges Refuse", value: "affiChargesRefuse" },
      { label: "Charges School Books", value: "affiChargesSchoolBooks" },
      { label: "Charges School Fees", value: "affiChargesSchoolFees" },
      { label: "Charges School Tours", value: "affiChargesSchoolTours" },
      { label: "Charges School Uniforms", value: "affiChargesSchoolUniforms" },
      {
        label: "Charges Sport/Other Activities",
        value: "affiChargesSportOtherActivities",
      },
      { label: "Charges Water Rates", value: "affiChargesWaterRates" },
      { label: "Children's Addresses", value: "affiChildrenAddresses" },
      { label: "Children's Details", value: "affiChildrenDetails" },
      {
        label: "Children's Health Details",
        value: "affiChildrenHealthDetails",
      },
      { label: "Contact With Applicant", value: "affiContactWithApplicant" },
      { label: "Contents Insurance", value: "affiContentsInsurance" },
      { label: "Court Order Details", value: "affiCourtOrderDetails" },
      {
        label: "Court Proceedings Details",
        value: "affiCourtProceedingsDetails",
      },
      { label: "Credit Card Balance", value: "affiCreditCardBalance" },
      { label: "Day To Day Care", value: "affiDayToDayCare" },
      { label: "Education Details", value: "affiEducationDetails" },
      { label: "Electricity", value: "affiElectricity" },
      { label: "Family Home Address", value: "affiFamilyHomeAddress" },
      { label: "Family Home Held In", value: "affiFamilyHomeHeldIn" },
      { label: "Family Home Market Value", value: "affiFamilyHomeMarketValue" },
      { label: "Family Home Mortgagee", value: "affiFamilyHomeMortgagee" },
      { label: "Fees Details", value: "affiFeesDetails" },
      { label: "Fifth Schedule", value: "affiFifthSchedule" },
      { label: "Fifth Schedule Details", value: "affiFifthScheduleDetails" },
      { label: "First Schedule", value: "affiFirstSchedule" },
      { label: "Fourth Schedule", value: "affiFourthSchedule" },
      {
        label: "Health Board/Supervision",
        value: "affiHealthBoardOrSupervision",
      },
      { label: "Heating", value: "affiHeating" },
      { label: "Holiday Care Details", value: "affiHolidayCareDetails" },
      { label: "House Insurance", value: "affiHouseInsurance" },
      {
        label: "Household Repairs/Maintenance",
        value: "affiHouseholdRepairsMaintenance",
      },
      { label: "Internet/Broadband", value: "affiInternetBroadband" },
      { label: "Loans", value: "affiLoans" },
      { label: "Maintenance", value: "affiMaintenance" },
      {
        label: "Maintenance Agreement Details",
        value: "affiMaintenanceAgreementDetails",
      },
      {
        label: "Maintenance Order Application",
        value: "affiMaintenanceOrderApplication",
      },
      {
        label: "Mortgage Account Balance",
        value: "affiMortgageAccountBalance",
      },
      { label: "Name", value: "affiName" },
      { label: "Other Carer Details", value: "affiOtherCarerDetails" },
      { label: "Other Income", value: "affiOtherIncome" },
      { label: "Other Persons' Details", value: "affiOtherPersonsDetails" },
      {
        label: "Overnight/Holiday Details",
        value: "affiOvernightHolidayDetails",
      },
      { label: "Ownership Details", value: "affiOwnershipDetails" },
      { label: "Payment Details", value: "affiPaymentDetails" },
      {
        label: "Rent/Mortgage Repayments",
        value: "affiRentMortgageRepayments",
      },
      { label: "Rooms Details", value: "affiRoomsDetails" },
      { label: "Salary", value: "affiSalary" },
      { label: "Schedule Details", value: "affiScheduleDetails" },
      { label: "Second Schedule", value: "affiSecondSchedule" },
      { label: "Shopping/Groceries", value: "affiShoppingGroceries" },
      { label: "Special Education Needs", value: "affiSpecialEducationNeeds" },
      { label: "Special Health Needs", value: "affiSpecialHealthNeeds" },
      { label: "Telephone (Landline)", value: "affiTelephoneLandline" },
      {
        label: "Telephone/TV/Internet Combined",
        value: "affiTelephoneTvInternetCombined",
      },
      { label: "Television License", value: "affiTelevisionLicense" },
      { label: "Third Schedule", value: "affiThirdSchedule" },
      { label: "Upkeep Details", value: "affiUpkeepDetails" },
      { label: "Weekly Outgoings", value: "affiWeeklyOutgoings" },
      { label: "Work Commitments", value: "affiWorkCommitments" },
      { label: "Car Make/Model", value: "affiCarMakeModel" },
    ];

  if (noFormData) {
    return (
      <Box
        w={"100%"}
        h={"calc(100vh - 160px)"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Text sx={{ fontSize: "22px" }}>No forms completed yet.</Text>
      </Box>
    );
  }

  // Marriage children and proceedings will now be dynamically rendered
  return (
    <>
      <Box
        sx={{
          p: isLargerThan600 ? "32px" : "16px",
          bg: "#f2f4f7",
          width: "100%",
        }}
      >
        <Button
          mb={4}
          bg={"#fff"}
          variant={"outline"}
          onClick={() => navigate("/dashboard/clients")}
        >
          Back
        </Button>

        {Object.keys(selectedClientForm).length > 0 && (
          <Box
            sx={{
              p: "16px",
              mb: "16px",
              border: "1px solid #dfdeda",
              borderRadius: "8px",
              bg: "#fff",
            }}
          >
            <Text sx={{ fontSize: "22px", fontWeight: "bold", pb: "16px" }}>
              Client Details
            </Text>
            <Box
              sx={{
                p: "16px",
                border: "1px solid #dfdeda",
                bg: "#f4f2ee",
                borderRadius: "8px",
                fontSize: "14px",
              }}
            >
              {clientDetails.map(({ label, value }) => (
                <Box key={value} sx={{ p: "4px 0", display: "flex" }}>
                  <Text sx={{ fontWeight: "bold" }}>{`${label}: `}</Text>
                  <Text sx={{ ml: 2 }}>{`${clientForm[value]}`}</Text>
                </Box>
              ))}
            </Box>
          </Box>
        )}

        {Object.keys(selectedSpouseForm).length > 0 && (
          <Box
            sx={{
              p: "16px",
              mb: "16px",
              border: "1px solid #dfdeda",
              borderRadius: "8px",
              background: "#fff",
            }}
          >
            <Text sx={{ fontSize: "22px", fontWeight: "bold", pb: "16px" }}>
              Spouse Details
            </Text>
            <Box
              sx={{
                p: "16px",
                border: "1px solid #dfdeda",
                borderRadius: "8px",
                bg: "#f4f2ee",
                fontSize: "14px",
              }}
            >
              {spouseDetails.map(({ label, value }) => (
                <Box key={value} sx={{ p: "4px 0", display: "flex" }}>
                  <Text sx={{ fontWeight: "bold" }}>{`${label}: `}</Text>
                  <Text sx={{ ml: 2 }}>{`${spouseForm[value]}`}</Text>
                </Box>
              ))}
            </Box>
          </Box>
        )}

        {Object.keys(selectedMarriageForm).length > 0 && (
          <Box
            sx={{
              p: "16px",
              mb: "16px",
              border: "1px solid #dfdeda",
              borderRadius: "8px",
              background: "#fff",
            }}
          >
            <Text sx={{ fontSize: "22px", fontWeight: "bold", pb: "16px" }}>
              Marriage Details
            </Text>
            <Box
              sx={{
                p: "16px",
                border: "1px solid #dfdeda",
                borderRadius: "8px",
                bg: "#f4f2ee",
                fontSize: "14px",
              }}
            >
              {marriageDetails.map((item, index) => {
                const { label, value } = item;
                return (
                  <Box key={index} sx={{ p: "4px 0", display: "flex" }}>
                    <Text sx={{ fontWeight: "bold" }}>{`${label}: `}</Text>
                    <Text sx={{ ml: 2 }}>{`${marriageForm[value]}`}</Text>
                  </Box>
                );
              })}

              <Text
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  mt: "16px",
                }}
              >
                Children of the Marriage
              </Text>
              {marriageForm.marriageChildrenDetails &&
                marriageForm.marriageChildrenDetails.map(
                  (child: MarriageChildDetails, index: number) => (
                    <Box key={index} sx={{ mb: "12px" }}>
                      <Text sx={{ fontWeight: "bold" }}>
                        Marriage Child {index + 1}
                      </Text>
                      <Text>Name: {child.name}</Text>
                      <Text>DOB: {child.dob}</Text>
                      <Text>Schools: {child.schools}</Text>
                    </Box>
                  )
                )}

              <Text sx={{ fontSize: "18px", fontWeight: "bold", mt: "16px" }}>
                Children Not of the Marriage
              </Text>
              {marriageForm.marriageNonMarriageChildrenDetails &&
                marriageForm.marriageNonMarriageChildrenDetails.map(
                  (child: MarriageChildDetails, index: number) => (
                    <Box key={index} sx={{ mb: "12px" }}>
                      <Text sx={{ fontWeight: "bold" }}>
                        Non-Marriage Child {index + 1}
                      </Text>
                      <Text>Name: {child.name}</Text>
                      <Text>DOB: {child.dob}</Text>
                      <Text>Schools: {child.schools}</Text>
                    </Box>
                  )
                )}

              {/* Display proceedings */}
              <Text sx={{ fontSize: "18px", fontWeight: "bold", mt: "16px" }}>
                Court Proceedings
              </Text>
              {marriageForm.marriageProceedingsDetails &&
                marriageForm.marriageProceedingsDetails.map(
                  (proceeding: MarriageProceedingDetails, index: number) => (
                    <Box key={index} sx={{ mb: "12px" }}>
                      <Text sx={{ fontWeight: "bold" }}>
                        Proceeding {index + 1}
                      </Text>
                      <Text>Court: {proceeding.court}</Text>
                      <Text>Matter: {proceeding.matter}</Text>
                      <Text>Date: {proceeding.date}</Text>
                      <Text>Outcome: {proceeding.outcome}</Text>
                    </Box>
                  )
                )}
            </Box>
          </Box>
        )}

        {Object.keys(selectedFamilyForm).length > 0 && (
          <Box
            sx={{
              p: "16px",
              mb: "16px",
              border: "1px solid #dfdeda",
              borderRadius: "8px",
              background: "#fff",
            }}
          >
            <Text sx={{ fontSize: "22px", fontWeight: "bold", pb: "16px" }}>
              Family Details
            </Text>
            <Box
              sx={{
                p: "16px",
                border: "1px solid #dfdeda",
                borderRadius: "8px",
                bg: "#f4f2ee",
                fontSize: "14px",
              }}
            >
              {familyDetails.map(({ label, value }) => {
                return (
                  <Box key={value} sx={{ p: "4px 0", display: "flex" }}>
                    <Text sx={{ fontWeight: "bold" }}>{`${label}: `}</Text>
                    <Text sx={{ ml: 2 }}>{`${familyForm[value]}`}</Text>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}

        {Object.keys(selectedAffidavitForm).length > 0 && (
          <Box
            sx={{
              p: "16px",
              mb: "16px",
              border: "1px solid #dfdeda",
              borderRadius: "8px",
              background: "#fff",
            }}
          >
            <Text sx={{ fontSize: "22px", fontWeight: "bold", pb: "16px" }}>
              Affidavit Details
            </Text>
            <Box
              sx={{
                p: "16px",
                border: "1px solid #dfdeda",
                borderRadius: "8px",
                bg: "#f4f2ee",
                fontSize: "14px",
              }}
            >
              {affidavitDetails.map(({ label, value }) => {
                return (
                  <Box key={value} sx={{ p: "4px 0", display: "flex" }}>
                    <Text sx={{ fontWeight: "bold" }}>{`${label}: `}</Text>
                    <Text sx={{ ml: 2 }}>{`${affidavitForm[value]}`}</Text>
                  </Box>
                );
              })}

              <Text
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  mt: "16px",
                }}
              >
                Child Details
              </Text>
              {affidavitForm.affiChildrenDetails &&
                affidavitForm.affiChildrenDetails.map(
                  (child: AffiChildDetails, index: number) => (
                    <Box key={index} sx={{ mb: "12px" }}>
                      <Text sx={{ fontWeight: "bold" }}>
                        Marriage Child {index + 1}
                      </Text>
                      <Text>Forenames: {child.forenames}</Text>
                      <Text>Surname: {child.surname}</Text>
                      <Text>DOB: {child.dob}</Text>
                    </Box>
                  )
                )}

              <Text
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  mt: "16px",
                }}
              >
                Financial Accounts
              </Text>
              {affidavitForm.affiFinancialAccounts &&
                affidavitForm.affiFinancialAccounts.map(
                  (account: FinancialAccount, index: number) => (
                    <Box key={index} sx={{ mb: "12px" }}>
                      <Text sx={{ fontWeight: "bold" }}>
                        Financial account {index + 1}
                      </Text>
                      <Text>Institution: {account.institution}</Text>
                      <Text>Balance: {account.balance}</Text>
                    </Box>
                  )
                )}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ClientDetails;
