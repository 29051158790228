import { Box, Text, useMediaQuery } from "@chakra-ui/react";
import { constants } from "constant";

const Footer = () => {
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");

  return (
    <Box
      sx={{
        width: "100%",
        height: constants.footer.height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#272727",
        textAlign: "center",
        color: "#fff",
        p: "16px",
      }}
    >
      <Text sx={{ fontSize: isLargerThan600 ? "16px" : "14px" }}>
        {constants.footer.copyright}
      </Text>
    </Box>
  );
};

export default Footer;
