import {
  Box,
  Button,
  Divider,
  Grid,
  GridItem,
  Spinner,
  Text,
  Tooltip,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import {
  IconFileUpload,
  IconCloudDownload,
  IconPlus,
} from "@tabler/icons-react";
import { useState, useRef } from "react";
import useStore from "store/store";
import { ADMIN_API_URL } from "config";
import { getDateNow } from "utils/getDateNow";

const Template = () => {
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const toast = useToast();
  const { updatedOn, setUpdatedOn } = useStore();
  const fileInputRef = useRef<HTMLInputElement>(null);

  /**
   * Handle file selection and validate its type.
   */
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) return;

    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      toast({
        title: "Invalid File Type",
        description: "Please upload a valid .docx file.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });
      setSelectedFile(null);
      setMessage("");
      return;
    }

    setSelectedFile(file);
    setMessage(`File: ${file.name}`);
  };

  /**
   * Handle file upload using a pre-signed URL.
   */
  const handleUpload = async () => {
    if (!selectedFile) {
      setMessage("Please select a file to upload.");
      return;
    }

    try {
      setLoading(true);

      // Step 1: Request a pre-signed URL from the backend
      const response = await fetch(`${ADMIN_API_URL}/upload-template`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          filename: selectedFile.name,
          filetype: selectedFile.type,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || "Failed to generate pre-signed URL."
        );
      }

      const { upload_url, s3_key } = await response.json();

      // Step 2: Upload the file to S3 using the pre-signed URL
      const s3Response = await fetch(upload_url, {
        method: "PUT",
        body: selectedFile,
        headers: {
          "Content-Type": selectedFile.type,
        },
      });

      if (!s3Response.ok) {
        throw new Error("Failed to upload file to S3.");
      }

      // Success: Update state and show success message
      setLoading(false);
      setSelectedFile(null);
      setMessage("");
      setUpdatedOn(getDateNow());

      // Clear the file input field
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Reset file input value
      }

      toast({
        title: "File Uploaded Successfully",
        description: `File uploaded to: ${s3_key}`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: "Upload Error",
        description: error instanceof Error ? error.message : "Upload failed.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Reset file input value
      }
      console.error("Upload Error: ", error);
    }
  };

  /**
   * Handle template download.
   */
  const downloadTemplate = async () => {
    try {
      const response = await fetch(`${ADMIN_API_URL}/download-template`);
      const data = await response.json();

      if (data.url) {
        window.location.href = data.url; // Trigger download
      } else {
        throw new Error("Failed to get download URL.");
      }
    } catch (error) {
      toast({
        title: "Download Error",
        description:
          error instanceof Error ? error.message : "Download failed.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });
      console.error("Download Error: ", error);
    }
  };

  /**
   * Render the Template component UI.
   */
  return (
    <Box padding={isLargerThan600 ? "32px" : "16px"} width="100%" height="100%">
      <Box padding="32px 16px">
        <Text fontSize="26px" fontWeight="bold">
          Template
        </Text>
      </Box>

      {/* Template Info Section */}
      <Box>
        <Grid
          padding={isLargerThan600 ? "8px 32px" : "8px"}
          templateColumns="1fr 1fr 1fr"
        >
          <GridItem
            width="100%"
            textAlign="left"
            fontWeight="bold"
            fontSize={isLargerThan600 ? "14px" : "12px"}
            color="#818181"
          >
            TEMPLATE DOCUMENT
          </GridItem>
          <GridItem
            width="100%"
            textAlign="center"
            fontWeight="bold"
            fontSize={isLargerThan600 ? "14px" : "12px"}
            color="#818181"
          >
            UPDATED ON
          </GridItem>
          <GridItem
            width="100%"
            textAlign="right"
            fontWeight="bold"
            fontSize={isLargerThan600 ? "14px" : "12px"}
            color="#818181"
          >
            ACTION
          </GridItem>
        </Grid>

        <Divider />

        <Grid
          padding={isLargerThan600 ? "8px 32px" : "8px"}
          templateColumns="1fr 1fr 1fr"
          alignItems="center"
        >
          <GridItem
            width="100%"
            textAlign="left"
            fontSize={isLargerThan600 ? "14px" : "12px"}
          >
            template.docx
          </GridItem>
          <GridItem
            width="100%"
            textAlign="center"
            fontSize={isLargerThan600 ? "14px" : "12px"}
          >
            {updatedOn}
          </GridItem>
          <GridItem width="100%" textAlign="right">
            <Tooltip
              hasArrow
              label="Download Template"
              bg="gray.300"
              color="black"
            >
              <Button
                isDisabled={loading}
                onClick={downloadTemplate}
                padding={isLargerThan600 ? "16px" : "8px"}
              >
                <IconCloudDownload size={22} />
              </Button>
            </Tooltip>
          </GridItem>
        </Grid>
        <Divider />
      </Box>

      {/* Upload Section */}
      <Box mt={3} border="2px dashed #dfdeda" borderRadius="8px" p="16px">
        <Text fontWeight="bold" p="8px">
          UPLOAD A NEW TEMPLATE FILE
        </Text>
        <Tooltip hasArrow label="Add a file" bg="gray.300" color="black">
          <Button
            as="label"
            htmlFor="file-upload"
            marginLeft={2}
            padding={isLargerThan600 ? "16px" : "8px"}
          >
            <IconPlus size={22} />
          </Button>
        </Tooltip>

        <input
          type="file"
          id="file-upload"
          accept=".docx"
          style={{ display: "none" }}
          onChange={handleFileChange}
          ref={fileInputRef}
        />

        <Tooltip hasArrow label="Upload Template" bg="gray.300" color="black">
          <Button
            marginLeft={2}
            padding={isLargerThan600 ? "16px" : "8px"}
            onClick={handleUpload}
          >
            {loading ? (
              <Spinner
                thickness="1px"
                speed="0.65s"
                emptyColor="gray.200"
                color="#c88a5d"
                size="md"
              />
            ) : (
              <IconFileUpload size={22} />
            )}
          </Button>
        </Tooltip>

        {message && (
          <Text fontSize="14px" marginTop={4}>
            {message}
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default Template;
