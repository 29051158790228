import {
  Box,
  Button,
  FormLabel,
  Select,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import "./form.styles.css";
import { useEffect, useState } from "react";
import useStore from "store/store";
import ConfirmSubmitModal from "components/modals/ConfirmSubmitModal";
import { scrollToTop } from "utils/ScrollToTop";
import {
  createClientFormData,
  fetchAndSetFormData,
  getClientFormData,
} from "services/api/form";
import FormInput from "components/inputs/FormInput";
import { IconFileCheck } from "@tabler/icons-react";
import { ClientForm as ClientInterface } from "types/form";

export default function ClientForm() {
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [unemployed, setUnemployed] = useState(false);
  const toast = useToast();

  const {
    openSubmitModal,
    updateForm,
    selectedClientForm,
    setSelectedClientForm,
    user,
    setApplicationIsComplete,
    forms,
    addCompletedForm,
    formsCompleted,
    setSelectedTab,
    setUser,
  } = useStore();

  const {
    control,
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<ClientInterface>({ defaultValues: selectedClientForm });

  const isComplete = forms.find((form) => form.id === 1)?.isComplete;

  // Reset form when selectedClientForm changes
  useEffect(() => {
    if (selectedClientForm) {
      reset(selectedClientForm);
    }
  }, [selectedClientForm, reset]);

  useEffect(() => {
    const fetchFormData = async () => {
      if (user?.clientFormStatus === "saved") {
        try {
          setLoading(true);
          await fetchAndSetFormData(
            setSelectedClientForm,
            getClientFormData,
            user!
          );
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error("Failed to fetch form data:", error);
        }
      }
    };

    fetchFormData();
  }, [setSelectedClientForm, user]);

  useEffect(() => {
    setSelectedTab(0);
  }, [setSelectedTab]);

  const handleSaveWithoutValidation = () => {
    const data = getValues();
    onSaveForm(data);
  };

  const onSaveForm = async (data: any) => {
    const { submission_id, submitted_at, ...rest } = data;
    const body = { ...rest, clientId: user?.userId };

    try {
      setSaving(true);
      const clientData = await createClientFormData(body);

      setUser({ ...user!, isLoggedIn: true, clientFormStatus: "saved" });
      setSelectedClientForm(clientData);
      toast({
        title: "Saved form.",
        description: "You've successfully saved this information.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
      setSaving(false);
    } catch (err: any) {
      console.error("Failed to fetch client data:", err);
      setSaving(false);
      toast({
        title: "Error saving form.",
        description: "An error occurred while saving this form.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };

  const onSubmitForm = (data: any) => {
    handleSubmit(() => {
      if (Object.keys(errors).length === 0) {
        openSubmitModal();
      } else {
        scrollToTop();
      }
    })(data);
  };

  const onFormConfirm = async (data: any) => {
    const { submission_id, submitted_at, ...rest } = data;
    const body = { ...rest, clientId: user?.userId };

    try {
      setLoading(true);
      const clientData = await createClientFormData(body);

      setUser({
        ...user!,
        isLoggedIn: true,
        clientFormStatus: "complete",
      });
      setSelectedClientForm(clientData);
      updateForm({ id: 1, isComplete: true });
      toast({
        title: "Saved form.",
        description: "You've successfully completed this form.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
      setLoading(false);
      addCompletedForm();
      scrollToTop();
      if (formsCompleted >= 5) {
        setApplicationIsComplete();
      }
    } catch (err: any) {
      console.error("Failed to fetch client data:", err);
      setLoading(false);
      toast({
        title: "Error saving form.",
        description: "An error occurred while completing this form.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };

  // Helper function to safely extract the error message as a string
  const getErrorMessage = (error: any): string | null => {
    if (error && error.message) {
      return error.message;
    }
    return null;
  };

  const handleEmploymentStatusChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    setUnemployed(value === "Unemployed");
  };

  const formLabelStyle = {
    fontWeight: 400,
  };

  const inputStyle = {
    padding: "4px",
    border: "1px solid #e2e8f0",
    borderRadius: "4px",
    background: "#f4f2ee",
  };

  if (loading) {
    return (
      <Box
        sx={{
          w: "100%",
          h: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="#c88a5d"
          size="xl"
        />
      </Box>
    );
  }

  if (
    isComplete ||
    user?.application_status === "complete" ||
    user?.clientFormStatus === "complete"
  )
    return (
      <Box sx={{ display: "flex", flexDirection: "column", color: "#404040" }}>
        <Box sx={{ padding: "32px 0" }}>
          <Text sx={{ fontWeight: "bold", fontSize: "20px" }}>
            CLIENT INFORMATION
          </Text>
        </Box>
        <Box sx={{ display: "flex" }}>
          <IconFileCheck size={22} color="#37ba32" />
          <Text sx={{ ml: 2 }}>The Client Information form is complete</Text>
        </Box>
      </Box>
    );

  return (
    <>
      <form>
        <Box
          sx={{ display: "flex", flexDirection: "column", color: "#404040" }}
        >
          <Box sx={{ padding: "32px 0" }}>
            <Text sx={{ fontWeight: "bold", fontSize: "20px" }}>
              CLIENT INFORMATION
            </Text>
          </Box>

          <>
            <FormInput
              id={"clientFirstName"}
              label={"FIRST NAME"}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"clientLastName"}
              label={"LAST NAME"}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"clientAddress"}
              label={"ADDRESS"}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"clientOccupants"}
              label={`NAME(S) OF OTHER OCCUPANTS AT THIS ADDRESS & THEIR
                  RELATIONSHIP TO SPOUSE`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"clientEmail"}
              label={"EMAIL"}
              register={register}
              registerOptions={{
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "Invalid email address",
                },
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"clientDob"}
              label={"DATE OF BIRTH"}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"date"}
              errors={errors}
            />

            <FormInput
              id={"clientPhone"}
              label={"PHONE NO."}
              register={register}
              registerOptions={{
                minLength: {
                  value: 6,
                  message: "Phone number must be at least 6 characters",
                },
                maxLength: {
                  value: 12,
                  message: "Phone number cannot exceed 12 characters",
                },
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"clientNationality"}
              label={"NATIONALITY"}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <Box
              sx={{
                padding: "8px 0",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormLabel
                style={formLabelStyle}
                htmlFor="clientEmploymentStatus"
              >
                EMPLOYMENT STATUS
              </FormLabel>
              <Controller
                control={control}
                name="clientEmploymentStatus"
                rules={{}}
                render={({ field }) => (
                  <Select
                    placeholder="Select"
                    id="clientEmploymentStatus"
                    style={inputStyle}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      handleEmploymentStatusChange(e);
                    }}
                  >
                    <option value="Employed">Employed</option>
                    <option value="Unemployed">Unemployed</option>
                  </Select>
                )}
              />
              {errors["clientEmploymentStatus"] && (
                <Text sx={{ fontSize: "12px", padding: "4px" }} color="red.500">
                  {getErrorMessage(errors["clientEmploymentStatus"])}
                </Text>
              )}

              {unemployed && (
                <FormInput
                  id={"clientUnemploymentDuration"}
                  label={"DURATION OF UNEMPLOYMENT"}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />
              )}
            </Box>

            <FormInput
              id={"clientCurrentEmployer"}
              label={"CURRENT EMPLOYER (or previous position if unemployed)"}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"clientSkills"}
              label={"SKILLS & QUALIFICATIONS (TRAINING/DIPLOMAS/ DEGREES)"}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"clientWorkingDays"}
              label={"WORKING DAY/HOURS"}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"clientSalary"}
              label={"SALARY"}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"clientPensionScheme"}
              label={"PENSION SCHEME"}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <Button
              onClick={handleSaveWithoutValidation}
              border="2px solid #c88a5d"
              color={"#c88a5d"}
              variant="outline"
              isDisabled={saving}
              mt={4}
            >
              {saving ? (
                <Spinner
                  thickness="1px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="#c88a5d"
                  size="md"
                />
              ) : (
                <Text> Save Client Information for later</Text>
              )}
            </Button>
            <Button bg="#c88a5d" color={"#fff"} onClick={onSubmitForm} mt={4}>
              Complete Client Information
            </Button>
          </>
        </Box>
      </form>
      <ConfirmSubmitModal onSubmit={handleSubmit(onFormConfirm)} />
    </>
  );
}
