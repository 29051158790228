import {
  AffidavitForm,
  ClientForm,
  FamilyForm,
  MarriageForm,
  SpouseForm,
} from "types/form";
import { StoreState } from "types/store";
import { User } from "types/user";
import { create, StateCreator } from "zustand";
import { persist, PersistOptions } from "zustand/middleware";

// Define the PersistOptions for Zustand
type MyPersist = (
  config: StateCreator<StoreState>,
  options: PersistOptions<StoreState>
) => StateCreator<StoreState>;

const useStore = create<StoreState>(
  (persist as MyPersist)(
    (set) => ({
      //------User-----//
      user: null,
      users: [],
      logout: () => {
        set({
          user: null,
          users: [],
          forms: [
            { id: 1, name: "CLIENT INFORMATION FORM", isComplete: false },
            { id: 2, name: "SPOUSE INFORMATION FORM", isComplete: false },
            { id: 3, name: "MARRIAGE FORM", isComplete: false },
            { id: 4, name: "FAMILY HOME FORM", isComplete: false },
            { id: 5, name: "AFFIDAVIT OF MEANS FORM", isComplete: false },
          ],
          formsCompleted: 0,
          selectedClientForm: {},
          selectedSpouseForm: {},
          selectedMarriageForm: {},
          selectedFamilyForm: {},
          selectedAffidavitForm: {},
          selectedTab: 0,
          isSidebarOpen: false,
          isModalOpen: false,
          isSubmitModalOpen: false,
          applicationIsComplete: false,
        });
        localStorage.removeItem("store");
      },
      setUser: (userData: User) => set({ user: userData }),
      setUsers: (users: User[]) => set({ users }),
      removeUser: (user: User) =>
        set((state) => ({
          users: state.users.filter((u: User) => u.userId !== user.userId),
        })),

      //------Form-----//
      forms: [
        { id: 1, name: "CLIENT INFORMATION FORM", isComplete: false },
        { id: 2, name: "SPOUSE INFORMATION FORM", isComplete: false },
        { id: 3, name: "MARRIAGE FORM", isComplete: false },
        { id: 4, name: "FAMILY HOME FORM", isComplete: false },
        { id: 5, name: "AFFIDAVIT OF MEANS FORM", isComplete: false },
      ],
      formsCompleted: 0,
      applicationIsComplete: false,
      setApplicationIsComplete: () =>
        set({
          applicationIsComplete: true,
        }),
      updateForm: (updatedForm: Partial<any>) =>
        set((state) => ({
          forms: state.forms.map((form) =>
            form.id === updatedForm.id ? { ...form, ...updatedForm } : form
          ),
        })),
      addCompletedForm: () =>
        set((state) => ({ formsCompleted: state.formsCompleted + 1 })),
      removeCompletedForm: () =>
        set((state) => ({ formsCompleted: state.formsCompleted - 1 })),

      //------Tab Index-----//
      selectedTab: 0,
      setSelectedTab: (index: number) => set({ selectedTab: index }),

      //------Sidebar State-----//
      isSidebarOpen: false,
      openSidebar: () => set({ isSidebarOpen: true }),
      closeSidebar: () => set({ isSidebarOpen: false }),

      //------Modal State-----//
      isModalOpen: false,
      openModal: () => set({ isModalOpen: true }),
      closeModal: () => set({ isModalOpen: false }),

      isSubmitModalOpen: false,
      openSubmitModal: () => set({ isSubmitModalOpen: true }),
      closeSubmitModal: () => set({ isSubmitModalOpen: false }),

      isDeleteModalOpen: false,
      openDeleteModal: () => set({ isDeleteModalOpen: true }),
      closeDeleteModal: () => set({ isDeleteModalOpen: false }),

      //------Client Forms State-----//
      selectedClientForm: {},
      selectedSpouseForm: {},
      selectedMarriageForm: {},
      selectedFamilyForm: {},
      selectedAffidavitForm: {},
      setSelectedClientForm: (selectedClientForm: ClientForm) =>
        set({ selectedClientForm }),
      setSelectedSpouseForm: (selectedSpouseForm: SpouseForm) =>
        set({ selectedSpouseForm }),
      setSelectedMarriageForm: (selectedMarriageForm: MarriageForm) =>
        set({ selectedMarriageForm }),
      setSelectedFamilyForm: (selectedFamilyForm: FamilyForm) =>
        set({ selectedFamilyForm }),
      setSelectedAffidavitForm: (selectedAffidavitForm: AffidavitForm) =>
        set({ selectedAffidavitForm }),
      clearSelectedClientForm: () => set({ selectedClientForm: {} }),
      clearSelectedSpouseForm: () => set({ selectedSpouseForm: {} }),
      clearSelectedMarriageForm: () => set({ selectedMarriageForm: {} }),
      clearSelectedFamilyForm: () => set({ selectedFamilyForm: {} }),
      clearSelectedAffidavitForm: () => set({ selectedAffidavitForm: {} }),

      //------Template State-----//
      updatedOn: "12-09-24, 08:29:32",
      setUpdatedOn: (date: string) => set({ updatedOn: date }),
    }),
    {
      name: "store", // Key for localStorage
      partialize: (state: StoreState): StoreState => ({
        ...state,
        // selectedClientForm: {},
        // selectedSpouseForm: {},
        // selectedMarriageForm: {},
        // selectedFamilyForm: {},
        // selectedAffidavitForm: {},
      }),
    }
  )
);

export default useStore;
