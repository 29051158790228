import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  VStack,
  useToast,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import logo from "../../assets/images/logo.png";
import { verifyUser } from "services/api/user";
import { useState, useEffect } from "react";

function VerifyEmailPage() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setFocus, // Added this function to set focus
  } = useForm();

  // Use effect to focus the input field when the component loads
  useEffect(() => {
    setFocus("verificationCode"); // Set focus on the verification code field
  }, [setFocus]);

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      await verifyUser({
        ...data,
        email: location.state.email.toLowerCase(),
      });

      setLoading(false);

      toast({
        title: "User verified.",
        description: "You've successfully verified your account",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });

      navigate("/login");
    } catch (err: any) {
      console.error("Error: ", err);
      setLoading(false);

      toast({
        title: "Verification failed.",
        description: "An error occurred while verifying your account.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });
    } finally {
      reset();
    }
  };

  return (
    <Box
      minH="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bg="gray.50"
    >
      <Box maxW="sm" w="full" bg="white" p={8} borderRadius="lg" boxShadow="lg">
        <Box sx={{ display: "flex", justifyContent: "center", pb: "32px" }}>
          <img
            src={logo}
            alt="Logo"
            width="250"
            onClick={() => navigate("/")}
          />
        </Box>
        <Heading mb={6} textAlign="center" size="lg">
          Verify Email
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4}>
            <FormControl
              id="verificationCode"
              isInvalid={!!errors.verificationCode}
            >
              <FormLabel>Verification Code</FormLabel>
              <Input
                type="text"
                placeholder="Enter your verification code"
                {...register("verificationCode", {
                  required: "Verification Code is required",
                })}
              />
              {errors.verificationCode?.message && (
                <Text sx={{ fontSize: "12px", padding: "4px" }} color="red.500">
                  {typeof errors.verificationCode.message === "string" &&
                    errors.verificationCode.message}
                </Text>
              )}
            </FormControl>
            <Button
              isDisabled={loading}
              type="submit"
              background={"#c88a5d"}
              color={"#fff"}
              size="lg"
              w="full"
            >
              {loading ? (
                <Spinner
                  thickness="1px"
                  speed="0.65s"
                  emptyColor="teal"
                  color="#c88a5d"
                  size="md"
                />
              ) : (
                <Text>Verify</Text>
              )}
            </Button>
          </VStack>
        </form>
      </Box>
    </Box>
  );
}

export default VerifyEmailPage;
