import { useRoutes } from "react-router-dom";
import Home from "pages/home";
import AdminGuard from "utils/route-guard/AdminGuard";
import AuthGuard from "utils/route-guard/AuthGuard";
import ClientForm from "pages/forms/ClientForm";
import SpouseForm from "pages/forms/SpouseForm";
import MarrageForm from "pages/forms/MarrageForm";
import FamilyForm from "pages/forms/FamilyForm";
import AffidavitForm from "pages/forms/AffidavitForm";
import CompleteForm from "pages/forms/CompleteForm";
import DashboardLayout from "components/layout/AdminLayout";
import Dashboard from "pages/dashboard/Dashboard";
import Clients from "pages/clients/Clients";
import Template from "pages/template/Template";
import ClientDetails from "pages/client-details/ClientDetails";
import LoginPage from "pages/authentication/Login";
import RegisterPage from "pages/authentication/Register";
import UserLayout from "components/layout/UserLayout";
import VerifyEmailPage from "pages/authentication/Verify";

function AppRoutes() {
  const routes = [
    {
      path: "/",

      element: (
        <AuthGuard>
          <Home />
        </AuthGuard>
      ),
    },
    {
      path: "forms",
      element: (
        <AuthGuard>
          <UserLayout />
        </AuthGuard>
      ),
      children: [
        { index: true, element: <ClientForm /> },
        {
          path: "spouse-information",
          element: <SpouseForm />,
        },
        {
          path: "marriage",
          element: <MarrageForm />,
        },
        {
          path: "family-home",
          element: <FamilyForm />,
        },
        {
          path: "affidavit",
          element: <AffidavitForm />,
        },
        {
          path: "complete-application",
          element: <CompleteForm />,
        },
      ],
    },
    {
      path: "dashboard",
      element: (
        <AdminGuard>
          <DashboardLayout />
        </AdminGuard>
      ),
      children: [
        { index: true, element: <Dashboard /> },
        {
          path: "clients",
          element: <Clients />,
        },
        {
          path: "template",
          element: <Template />,
        },
        {
          path: "client-details",
          element: <ClientDetails />,
        },
      ],
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/register",
      element: <RegisterPage />,
    },
    {
      path: "/veriy-email",
      element: <VerifyEmailPage />,
    },
  ];

  // Use the `useRoutes` hook to define the route structure
  const element = useRoutes(routes);

  return element;
}

export default AppRoutes;
