import { Box, FormLabel, Input, Textarea, Text } from "@chakra-ui/react";
import { useMemo } from "react";

interface FormInputProps {
  id: string;
  label: string;
  register: any;
  registerOptions: any;
  type?: string;
  errors: any;
  isTextarea?: boolean;
}

const FormInput = ({
  id,
  label,
  register,
  registerOptions,
  type = "text",
  errors,
  isTextarea = false,
}: FormInputProps) => {
  const formLabelStyle = useMemo(() => ({ fontWeight: 400 }), []);
  const inputStyle = useMemo(
    () => ({
      padding: "4px",
      border: "1px solid #e2e8f0",
      borderRadius: "4px",
      background: "#f4f2ee",
    }),
    []
  );

  return (
    <Box sx={{ padding: "8px 0", display: "flex", flexDirection: "column" }}>
      <FormLabel style={formLabelStyle} htmlFor={id}>
        {label}
      </FormLabel>
      {isTextarea ? (
        <Textarea
          id={id}
          style={inputStyle}
          {...register(id, registerOptions)}
        />
      ) : (
        <Input
          id={id}
          style={inputStyle}
          type={type}
          {...register(id, registerOptions)}
        />
      )}
      {errors[id]?.message && (
        <Text sx={{ fontSize: "12px", padding: "4px" }} color="red.500">
          {errors[id].message}
        </Text>
      )}
    </Box>
  );
};

export default FormInput;
