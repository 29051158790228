import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import useStore from "store/store";

interface ConfirmSubmitModalProps {
  onSubmit: () => void;
}

const ConfirmSubmitModal = ({ onSubmit }: ConfirmSubmitModalProps) => {
  const { isSubmitModalOpen, closeSubmitModal } = useStore();

  const handleConfirm = () => {
    onSubmit();
    closeSubmitModal();
  };

  return (
    <Modal isOpen={isSubmitModalOpen} onClose={closeSubmitModal} isCentered>
      <ModalOverlay />
      <ModalContent sx={{ maxWidth: "80vw" }}>
        <ModalHeader>Confirm Submission</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Are you sure you want to submit this form? Once submitted, you won't
            be able to make any changes. If you need to make edits later, please
            save your progress by clicking the 'Save for Later' button.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={closeSubmitModal}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleConfirm}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmSubmitModal;
