import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import {
  IconLogout,
  IconChevronDown,
  IconLayoutDashboard,
  IconFiles,
  IconHome,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import useStore from "store/store";

const DropdownMenu = () => {
  const navigate = useNavigate();
  const { logout, user } = useStore();

  return (
    <Menu closeOnSelect={true}>
      <Box>
        <MenuButton
          border={"2px solid #c88a5d"}
          color={"#32312f"}
          as={Button}
          variant={"outlined"}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Text sx={{ pr: 1 }}>Menu</Text>
            <IconChevronDown size={18} stroke={2} />
          </Box>
        </MenuButton>
        <MenuList
          sx={{ color: "#000", border: "1px solid #dfdeda" }}
          minWidth="240px"
        >
          <MenuGroup title="User">
            <MenuItem onClick={() => navigate("/")}>
              <IconHome stroke={1} size={24} color={"#c88a5d"} />
              <Text sx={{ color: "#000", ml: 2, fontSize: "14px" }}>Home</Text>
            </MenuItem>
            <MenuItem onClick={() => navigate("/forms")}>
              <IconFiles stroke={1} size={24} color={"#c88a5d"} />
              <Text sx={{ color: "#000", ml: 2, fontSize: "14px" }}>Forms</Text>
            </MenuItem>
          </MenuGroup>
          <MenuDivider />

          {user?.role === "admin" && (
            <>
              <MenuGroup title="Admin">
                <MenuItem onClick={() => navigate("/dashboard")}>
                  <IconLayoutDashboard stroke={1} size={24} color={"#c88a5d"} />
                  <Text sx={{ color: "#000", ml: 2, fontSize: "14px" }}>
                    Dashboard
                  </Text>
                </MenuItem>
              </MenuGroup>
              <MenuDivider />
            </>
          )}

          <MenuGroup>
            <MenuItem
              onClick={() => {
                logout();
                navigate("/");
              }}
            >
              <IconLogout stroke={1} size={24} color={"#c88a5d"} />
              <Text sx={{ color: "#000", ml: 2, fontSize: "14px" }}>
                Logout
              </Text>
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Box>
    </Menu>
  );
};

export default DropdownMenu;
