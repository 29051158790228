import { PUBLIC_API_URL, ADMIN_API_URL } from "config";
import httpClient from "./httpClient";
import { DeleteUser, UpdateUserFroms, UpdateUser, User } from "types/user";

export function loginUser(userData: any): Promise<any> {
  return httpClient.post(`${PUBLIC_API_URL}/login`, userData).then((resp) => {
    return resp.data ?? {};
  });
}

export function registerUser(userData: any): Promise<User> {
  return httpClient
    .post(`${PUBLIC_API_URL}/register`, userData)
    .then((resp) => {
      return resp.data ?? {};
    });
}

export function verifyUser(userData: any): Promise<any> {
  return httpClient
    .post(`${PUBLIC_API_URL}/verify-email`, userData)
    .then((resp) => {
      return resp.data ?? {};
    });
}

export function getUser(email: string): Promise<User> {
  return httpClient.get(`${ADMIN_API_URL}/users/${email}`).then((resp) => {
    return resp.data ?? [];
  });
}

export function getUsers(): Promise<User[]> {
  return httpClient.get(`${ADMIN_API_URL}/users`).then((resp) => {
    return resp.data ?? [];
  });
}

//------- For testing -------//
// export function getUsers(): Promise<any> {
//   return fetch("/mockData/clients.json")
//     .then((response) => {
//       if (!response.ok) {
//         throw new Error("Failed to fetch users");
//       }
//       return response.json();
//     })
//     .then((data) => data ?? [])
//     .catch((error) => {
//       console.error("Error fetching users:", error);
//       return [];
//     });
// }

export function updateUser(userData: UpdateUser): Promise<any> {
  return httpClient.put(`${ADMIN_API_URL}/users`, userData).then((resp) => {
    return resp.data ?? [];
  });
}

export function deleteUser(userData: DeleteUser): Promise<any> {
  return httpClient.post(`${ADMIN_API_URL}/users`, userData).then((resp) => {
    return resp.data ?? [];
  });
}

export function updateUserForms(userData: UpdateUserFroms): Promise<any> {
  return httpClient
    .put(`${ADMIN_API_URL}/users/update-form`, userData)
    .then((resp) => {
      return resp.data ?? [];
    });
}
