import { FC } from "react";
import { Box, Button, Text, useMediaQuery } from "@chakra-ui/react";
import styled from "@emotion/styled";
import Logo from "components/logo/Logo";
import { constants } from "constant";
import { useNavigate } from "react-router-dom";
import useStore from "store/store";
import { IconMenu4, IconLogout } from "@tabler/icons-react";
import DropdownMenu from "components/navigation/dropdown-menu/DropDownMenu";

const NavContainer = styled(Box)(({ theme }) => ({
  color: "#fff",
  height: constants.navbar.height,
}));

interface NavbarProps {}

const Navbar: FC<NavbarProps> = () => {
  const { user, logout, openSidebar } = useStore();
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <NavContainer color="transparent" elevation={0}>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: isLargerThan600 ? "0 40px" : "0 12px",
          borderBottom: "1px solid #dfdeda",
        }}
      >
        <Box>
          <Logo />
        </Box>

        {!isLargerThan600 && (
          <Box sx={{ display: "flex" }}>
            <Button onClick={openSidebar} variant={"outline"} sx={{ ml: 2 }}>
              <IconMenu4 stroke={1} />
            </Button>

            {user?.isLoggedIn && (
              <Button onClick={handleLogout} variant={"outline"} sx={{ ml: 2 }}>
                {isLargerThan600 && <Text>Logout</Text>}
                {!isLargerThan600 && <IconLogout stroke={1} />}
              </Button>
            )}
          </Box>
        )}

        {isLargerThan600 && <DropdownMenu />}
      </Box>
    </NavContainer>
  );
};

export default Navbar;
