import {
  Box,
  Button,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
  useMediaQuery,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { IconArrowNarrowRight } from "@tabler/icons-react";

import Navbar from "components/navigation/navbar/Navbar";
import SideMenu from "components/navigation/side-menu/SideMenu";
import Footer from "components/navigation/footer";

const Home = () => {
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <SideMenu />

      <Box
        sx={{
          p: isLargerThan600 ? "64px 128px" : "32px",
          minHeight: "calc(100vh - 160px)",
        }}
      >
        <Text sx={{ pb: "16px", fontSize: "22px", fontWeight: "bold" }}>
          Starter Pack and Information Gathering Documents:
        </Text>
        <Text sx={{ pb: "16px" }}>
          Please complete the following forms with as much information as
          possible and submit the forms. Please note that you can save and
          return to the forms at any time.
        </Text>
        <OrderedList sx={{ pb: "16px" }}>
          <ListItem>General Information gathering for Civil Billt</ListItem>
          <ListItem>Blank Affidavit of Means</ListItem>
          <ListItem>Blank Affidavit of Welfare</ListItem>
        </OrderedList>
        <Text sx={{ pb: "16px" }}>
          Following this, your Divorce Application Documents can be drafted.
        </Text>
        <Text sx={{ pb: "8px", fontSize: "18px", fontWeight: "bold" }}>
          Kilkenny Office:
        </Text>
        <UnorderedList
          sx={{ pb: "16px", listStyleType: "none", margin: "0px" }}
        >
          <ListItem>Richard O’Shea</ListItem>
          <ListItem>Divorce Paper Solutions Limited</ListItem>
          <ListItem>No. 2 Rose Inn Street</ListItem>
          <ListItem>Kilkenny</ListItem>
        </UnorderedList>
        <Button
          mt={3}
          background={"#c88a5d"}
          color={"#fff"}
          onClick={() => navigate("/forms")}
        >
          <Text>Continue to forms</Text>
          <IconArrowNarrowRight style={{ marginLeft: "8px" }} />
        </Button>
      </Box>

      <Footer />
    </>
  );
};

export default Home;
