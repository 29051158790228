import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  VStack,
  useToast,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useStore from "store/store";
import logo from "../../assets/images/logo.png";
import { loginUser } from "services/api/user";
import { useState } from "react";
import { saveToken } from "services/api/httpClient";

function LoginPage() {
  const [loading, setLoading] = useState(false);
  const { setUser } = useStore();
  const navigate = useNavigate();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const { user, id_token } = await loginUser({
        ...data,
        email: data.email.toLowerCase(),
      });

      setUser({ ...user, isLoggedIn: true });
      saveToken(id_token);
      setLoading(false);

      toast({
        title: "Logged in.",
        description: "You've successfully logged in.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });

      navigate("/");
    } catch (err: any) {
      console.error("Error: ", err);
      setLoading(false);

      toast({
        title: "Login failed.",
        description: "An error occurred while logging in.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });
    } finally {
      reset();
    }
  };

  return (
    <Box
      minH="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bg="gray.50"
    >
      <Box maxW="sm" w="full" bg="white" p={8} borderRadius="lg" boxShadow="lg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pb: "16px",
            cursor: "pointer",
          }}
        >
          <img src={logo} alt="Logo" onClick={() => navigate("/")} />
        </Box>
        <Heading mb={6} textAlign="center" size="lg">
          Login
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4}>
            <FormControl id="email" isInvalid={!!errors.email}>
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                placeholder="Enter your email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: "Entered value does not match email format",
                  },
                })}
              />
              {errors.email?.message && (
                <Text sx={{ fontSize: "12px", padding: "4px" }} color="red.500">
                  {typeof errors.email.message === "string" &&
                    errors.email.message}
                </Text>
              )}
            </FormControl>
            <FormControl id="password" isInvalid={!!errors.password}>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                placeholder="Enter your password"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                })}
              />
              {errors.password?.message && (
                <Text sx={{ fontSize: "12px", padding: "4px" }} color="red.500">
                  {typeof errors.password.message === "string" &&
                    errors.password.message}
                </Text>
              )}
            </FormControl>
            <Button
              isDisabled={loading}
              type="submit"
              background={"#c88a5d"}
              color={"#fff"}
              size="lg"
              w="full"
            >
              {loading ? (
                <Spinner
                  thickness="1px"
                  speed="0.65s"
                  emptyColor="teal"
                  color="#c88a5d"
                  size="md"
                />
              ) : (
                <Text>Login</Text>
              )}
            </Button>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Text>Not registered yet?</Text>
              <Button
                isDisabled={loading}
                sx={{
                  p: "4px 8px",
                  ml: 2,
                  background: "#fff",
                  color: "#1c8fff",
                  textDecoration: "underline",
                }}
                onClick={() => navigate("/register")}
              >
                Register here
              </Button>
            </Box>
          </VStack>
        </form>
      </Box>
    </Box>
  );
}

export default LoginPage;
