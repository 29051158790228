import { useNavigate, useLocation } from "react-router-dom";
import { ReactElement, useEffect } from "react";
import useStore from "store/store";

interface AuthGuardProps {
  children: ReactElement;
}

const AuthGuard = ({ children }: AuthGuardProps) => {
  const { user } = useStore();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const allowedRoutes = ["/verify-email"];

    // Check if the current path is one of the allowed routes
    if (!user?.isLoggedIn && !allowedRoutes.includes(location.pathname)) {
      navigate("/login", { replace: true });
    }
  }, [user?.isLoggedIn, location.pathname, navigate]);

  return children;
};

export default AuthGuard;
