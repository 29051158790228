import { Box, Input, FormLabel, Text } from "@chakra-ui/react";
import { useMemo } from "react";

interface ProceedingInputProps {
  childIndex: number;
  register: any;
  errors: any;
  id: string;
  title: string;
}

const ProceedingInput = ({
  register,
  childIndex,
  errors,
  id,
  title,
}: ProceedingInputProps) => {
  const formLabelStyle = useMemo(() => ({ fontWeight: 400 }), []);
  const inputStyle = useMemo(
    () => ({
      padding: "4px",
      border: "1px solid #e2e8f0",
      borderRadius: "4px",
      background: "#f4f2ee",
    }),
    []
  );

  return (
    <Box sx={{ padding: "16px 0" }}>
      <Text sx={{ fontWeight: "bold" }}>{`${title} ${childIndex + 1}`}</Text>

      {/* Court Field */}
      <Box sx={{ padding: "8px 0", display: "flex", flexDirection: "column" }}>
        <FormLabel sx={formLabelStyle}>Court</FormLabel>
        <Input
          sx={inputStyle}
          {...register(`${id}.court`, {
            required: "Court is required",
          })}
          type="text"
          isInvalid={!!errors[`${id}`]?.court}
        />
        {errors[`${id}`]?.court && (
          <Text color="red">{errors[`${id}`]?.court.message}</Text>
        )}
      </Box>

      {/* Matter Field */}
      <Box sx={{ padding: "8px 0", display: "flex", flexDirection: "column" }}>
        <FormLabel sx={formLabelStyle}>Matter</FormLabel>
        <Input
          sx={inputStyle}
          {...register(`${id}.matter`, {
            required: "Matter is required",
          })}
          type="text"
          isInvalid={!!errors[`${id}`]?.matter}
        />
        {errors[`${id}`]?.matter && (
          <Text color="red">{errors[`${id}`]?.matter.message}</Text>
        )}
      </Box>

      {/* Date Field */}
      <Box sx={{ padding: "8px 0", display: "flex", flexDirection: "column" }}>
        <FormLabel sx={formLabelStyle}>Date</FormLabel>
        <Input
          sx={inputStyle}
          {...register(`${id}.date`, {
            required: "Date is required",
          })}
          type="date"
          isInvalid={!!errors[`${id}`]?.date}
        />
        {errors[`${id}`]?.date && (
          <Text color="red">{errors[`${id}`]?.date.message}</Text>
        )}
      </Box>

      {/* Outcome Field */}
      <Box sx={{ padding: "8px 0", display: "flex", flexDirection: "column" }}>
        <FormLabel sx={formLabelStyle}>Outcome</FormLabel>
        <Input
          sx={inputStyle}
          {...register(`${id}.outcome`, {
            required: "Outcome is required",
          })}
          type="text"
          isInvalid={!!errors[`${id}`]?.outcome}
        />
        {errors[`${id}`]?.outcome && (
          <Text color="red">{errors[`${id}`]?.outcome.message}</Text>
        )}
      </Box>
    </Box>
  );
};

export default ProceedingInput;
