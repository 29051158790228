import { useNavigate } from "react-router-dom";
import { ReactElement, useEffect } from "react";
import useStore from "store/store";

// ==============================|| ADMIN GUARD ||============================== //

interface AdminGuardProps {
  children: ReactElement;
}

const AdminGuard = ({ children }: AdminGuardProps) => {
  const { user } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.role !== "admin" || !user?.isLoggedIn) {
      navigate("/login", { replace: true });
    }
  }, [user?.role, user?.isLoggedIn, navigate]);

  return children;
};

export default AdminGuard;
