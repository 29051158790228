import {
  Box,
  Button,
  Divider,
  FormLabel,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import "./form.styles.css";
import { useEffect, useState } from "react";
import { scrollToTop } from "utils/ScrollToTop";
import useStore from "store/store";
import {
  createFamilyFormData,
  fetchAndSetFormData,
  getFamilyFormData,
} from "services/api/form";
import ConfirmSubmitModal from "components/modals/ConfirmSubmitModal";
import FormInput from "components/inputs/FormInput";
import { IconFileCheck } from "@tabler/icons-react";
import { FamilyForm as FamilyInterface } from "types/form";

export default function FamilyForm() {
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [additionalProperty, setAdditionalProperty] = useState(false);
  const toast = useToast();

  const {
    openSubmitModal,
    updateForm,
    selectedFamilyForm,
    setSelectedFamilyForm,
    user,
    setApplicationIsComplete,
    forms,
    addCompletedForm,
    formsCompleted,
    setUser,
  } = useStore();

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<FamilyInterface>({ defaultValues: selectedFamilyForm });

  const isComplete = forms.find((form) => form.id === 4)?.isComplete;

  // Reset form when selectedClientForm changes
  useEffect(() => {
    if (selectedFamilyForm) {
      reset(selectedFamilyForm);
    }
  }, [selectedFamilyForm, reset]);

  useEffect(() => {
    console.info("rendered");

    const fetchFamilyFormData = async () => {
      if (user?.familyFormStatus === "saved") {
        try {
          setLoading(true); // Optional: if you're managing loading state
          await fetchAndSetFormData(
            setSelectedFamilyForm,
            getFamilyFormData,
            user!
          );
          setLoading(false); // Optional: reset loading state after fetching data
        } catch (error) {
          setLoading(false); // Optional: reset loading state in case of error
          console.error("Failed to fetch family form data:", error);
        }
      }
    };

    fetchFamilyFormData();
  }, [setSelectedFamilyForm, user]);

  const handleSaveWithoutValidation = () => {
    const data = getValues();
    onSaveForm(data);
  };

  const onSaveForm = async (data: any) => {
    const { submission_id, submitted_at, message, ...rest } = data;
    const body = { ...rest, clientId: user?.userId };

    try {
      setSaving(true);
      const familyData = await createFamilyFormData(body);

      setUser({ ...user!, isLoggedIn: true, familyFormStatus: "saved" });
      setSelectedFamilyForm(familyData);
      toast({
        title: "Saved form.",
        description: "You've successfully saved this information.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
      setSaving(false);
    } catch (err: any) {
      console.error("Failed to fetch client data:", err);
      setSaving(false);
      toast({
        title: "Error saving form.",
        description: "An error occurred while saving this form.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };

  const onSubmitForm = (data: any) => {
    handleSubmit(() => {
      if (Object.keys(errors).length === 0) {
        openSubmitModal();
      } else {
        scrollToTop();
      }
    })(data);
  };

  const onFormConfirm = async (data: any) => {
    const { submission_id, submitted_at, message, ...rest } = data;
    const body = { ...rest, clientId: user?.userId };

    try {
      setLoading(true);
      const familyData = await createFamilyFormData(body);

      setUser({
        ...user!,
        isLoggedIn: true,
        familyFormStatus: "complete",
      });
      setSelectedFamilyForm(familyData);
      updateForm({ id: 4, isComplete: true });
      toast({
        title: "Saved form.",
        description: "You've successfully completed this form.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });
      setLoading(false);
      addCompletedForm();
      scrollToTop();
      if (formsCompleted >= 5) {
        setApplicationIsComplete();
      }
    } catch (err: any) {
      console.error("Failed to fetch client data:", err);
      setLoading(false);
      toast({
        title: "Error saving form.",
        description: "An error occurred while completing this form.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          w: "100%",
          h: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="#c88a5d"
          size="xl"
        />
      </Box>
    );
  }

  if (
    isComplete ||
    user?.application_status === "complete" ||
    user?.familyFormStatus === "complete"
  )
    return (
      <Box sx={{ display: "flex", flexDirection: "column", color: "#404040" }}>
        <Box sx={{ padding: "32px 0" }}>
          <Text sx={{ fontWeight: "bold", fontSize: "20px" }}>FAMILY HOME</Text>
        </Box>
        <Box sx={{ display: "flex" }}>
          <IconFileCheck size={22} color="#37ba32" />
          <Text sx={{ ml: 2 }}>The Family Information form is complete</Text>
        </Box>
      </Box>
    );

  return (
    <>
      <form>
        <Box
          sx={{ display: "flex", flexDirection: "column", color: "#404040" }}
        >
          <Box sx={{ padding: "32px 0" }}>
            <Text sx={{ fontWeight: "bold", fontSize: "20px" }}>
              FAMILY HOME
            </Text>
          </Box>

          <>
            <FormInput
              id={"familyAddress"}
              label={"ADDRESS"}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"familyPurchasedOrRented"}
              label={`FREEHOLD/LEASEHOLD i.e., is it purchased or rented and, if
                  rented, from whom`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"familyDateOfPurchase"}
              label={`DATE OF PURCHASE`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"date"}
              errors={errors}
            />

            <FormInput
              id={"familyPurchasePrice"}
              label={`PURCHASE PRICE`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"familyDepositAmount"}
              label={`DEPOSIT AMOUNT`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"familyDepositContributionsByWife"}
              label={`CONTRIBUTIONS BY WIFE TOWARDS DEPOSIT`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"familyDepositContributionsByHusband"}
              label={`CONTRIBUTIONS BY HUSBAND TOWARDS DEPOSIT`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"familyPrevPropProceeds"}
              label={`STATE IS PROCEEDS FROM PREVIOUS PROPERTY WENT TOWARDS THE
                  PURCHASE PRICE. IF SO, HOW MUCH?`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"familyPresentValue"}
              label={`PRESENT VALUE`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"familyTopupDetails"}
              label={`TOPUP – STATE HOW MANY, FOR WHAT AMOUNT & WHEN`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"familyBankDetails"}
              label={`NAME OF BUILDING SOCIETY/BANK LOCAL AUTHORITY`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"familyMonthlyMortgage"}
              label={`MONTHLY MORTGAGE REPAYMENTS`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"familyMortgagePayer"}
              label={`WHO PAYS THE MORTGAGE & HOW MUCH?`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"familyArrearsAmount"}
              label={`ANY ARREARS. IF YES, STATE AMOUNT`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"familyMortgageDue"}
              label={`AMOUNT DUE ON MORTGAGE`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <Divider sx={{ margin: "16px 0", border: " 1px solid #e8e8e8" }} />

            <Box>
              <Box sx={{ padding: "32px 0" }}>
                <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>
                  ADDITIONAL PROPERTY OWNED BY YOU.
                </Text>
              </Box>

              <Box
                sx={{
                  padding: "8px 0",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <FormLabel
                  style={{ paddingBottom: "4px" }}
                  htmlFor="familyAdditionalProperty"
                >
                  Do you own additional property?
                </FormLabel>
                <Box>
                  <Box sx={{ display: "flex", pb: "8px" }}>
                    <input
                      {...register("familyAdditionalProperty", {
                        required: false,
                      })}
                      type="radio"
                      value="Yes"
                      onChange={() => setAdditionalProperty(true)}
                    />
                    <Text sx={{ paddingLeft: 2 }}>Yes</Text>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <input
                      {...register("familyAdditionalProperty", {
                        required: false,
                      })}
                      type="radio"
                      value="No"
                      onChange={() => setAdditionalProperty(false)}
                    />
                    <Text sx={{ paddingLeft: 2 }}>No</Text>
                  </Box>
                </Box>
              </Box>

              {additionalProperty && (
                <>
                  <FormInput
                    id={"familyAddHomePropertyAddress"}
                    label={`ADDRESS`}
                    register={register}
                    registerOptions={{
                      maxLength: 80,
                    }}
                    type={"text"}
                    errors={errors}
                  />

                  <FormInput
                    id={"familyAddHomePropertyType"}
                    label={`FREEHOLD/LEASEHOLD i.e., is it purchased or rented and,
                        if rented, from whom`}
                    register={register}
                    registerOptions={{
                      maxLength: 80,
                    }}
                    type={"text"}
                    errors={errors}
                  />

                  <FormInput
                    id={"familyAddHomeDateOfPurchase"}
                    label={`DATE OF PURCHASE`}
                    register={register}
                    registerOptions={{
                      maxLength: 80,
                    }}
                    type={"text"}
                    errors={errors}
                  />

                  <FormInput
                    id={"familyAddHomePurchasePrice"}
                    label={`PURCHASE PRICE`}
                    register={register}
                    registerOptions={{
                      maxLength: 80,
                    }}
                    type={"text"}
                    errors={errors}
                  />

                  <FormInput
                    id={"familyAddHomeDepositAmount"}
                    label={`DEPOSIT AMOUNT`}
                    register={register}
                    registerOptions={{
                      required: "Field is required",
                      maxLength: 80,
                    }}
                    type={"text"}
                    errors={errors}
                  />

                  <FormInput
                    id={"familyAddHomeWifeContribution"}
                    label={`CONTRIBUTIONS BY WIFE TOWARDS DEPOSIT`}
                    register={register}
                    registerOptions={{
                      maxLength: 80,
                    }}
                    type={"text"}
                    errors={errors}
                  />

                  <FormInput
                    id={"familyAddHomeHusbandContribution"}
                    label={`CONTRIBUTIONS BY HUSBAND TOWARDS DEPOSIT`}
                    register={register}
                    registerOptions={{
                      maxLength: 80,
                    }}
                    type={"text"}
                    errors={errors}
                  />

                  <FormInput
                    id={"familyAddHomePresentValue"}
                    label={`PRESENT VALUE`}
                    register={register}
                    registerOptions={{
                      maxLength: 80,
                    }}
                    type={"text"}
                    errors={errors}
                  />

                  <FormInput
                    id={"familyAddHomeMortgageYn"}
                    label={`MORTGAGE (Y/N)`}
                    register={register}
                    registerOptions={{
                      maxLength: 80,
                    }}
                    type={"text"}
                    errors={errors}
                  />

                  <FormInput
                    id={"familyAddHomeBankAuthority"}
                    label={`NAME OF BUILDING SOCIETY/BANK LOCAL AUTHORITY`}
                    register={register}
                    registerOptions={{
                      maxLength: 80,
                    }}
                    type={"text"}
                    errors={errors}
                  />

                  <FormInput
                    id={"familyAddHomeMonthlyMortgage"}
                    label={`MONTHLY MORTGAGE € REPAYMENTS`}
                    register={register}
                    registerOptions={{
                      maxLength: 80,
                    }}
                    type={"text"}
                    errors={errors}
                  />

                  <FormInput
                    id={"familyAddHomeInterestOnly"}
                    label={`IF INTEREST ONLY PAYMENTS AT PRESENT, STATE AMOUNT`}
                    register={register}
                    registerOptions={{
                      maxLength: 80,
                    }}
                    type={"text"}
                    errors={errors}
                  />

                  <FormInput
                    id={"familyAddHomeArrearsAmount"}
                    label={`ANY ARREARS. IF YES, STATE AMOUNT`}
                    register={register}
                    registerOptions={{
                      maxLength: 80,
                    }}
                    type={"text"}
                    errors={errors}
                  />

                  <FormInput
                    id={"familyAddHomeMortgageDue"}
                    label={`AMOUNT DUE ON MORTGAGE`}
                    register={register}
                    registerOptions={{
                      maxLength: 80,
                    }}
                    type={"text"}
                    errors={errors}
                  />

                  <FormInput
                    id={"familyAddHomeCourtProceedings"}
                    label={`ANY COURT PROCEEDINGS BY THE BANK IN RELATION TO
                        PROPERTY?`}
                    register={register}
                    registerOptions={{
                      maxLength: 80,
                    }}
                    type={"text"}
                    errors={errors}
                  />
                </>
              )}
              <Divider
                sx={{ margin: "16px 0", border: " 1px solid #e8e8e8" }}
              />
            </Box>

            <Box>
              <Box sx={{ padding: "32px 0" }}>
                <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>
                  MAINTENANCE FOR CHILDREN
                </Text>

                <FormInput
                  id={"familyMaintenanceChildrenWeekly"}
                  label={`HOW MUCH PAID PER WEEK?`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={"familyMaintenanceCourtOrderDate"}
                  label={`IF UNDER COURT ORDER, DATE OF COURT ORDER`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"date"}
                  errors={errors}
                />

                <FormInput
                  id={"familyMaintenanceSource"}
                  label={`SOURCE? i.e., Wages, social welfare, attachment of
                      earnings, earnings from other sources`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={"familyMaintenanceArrears"}
                  label={`ARREARS?`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />
              </Box>
              <Divider sx={{ margin: "16px 0", border: "1px solid #e8e8e8" }} />
            </Box>

            <Box>
              <Box sx={{ padding: "32px 0" }}>
                <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>
                  MAINTENANCE FOR/FROM SPOUSE
                </Text>

                <FormInput
                  id={"familySpouseMaintenanceWeekly"}
                  label={`HOW MUCH PAID PER WEEK?`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={"familySpouseMaintCourtOrderDate"}
                  label={`IF UNDER COURT ORDER, DATE OF COURT ORDER`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"date"}
                  errors={errors}
                />

                <FormInput
                  id={"familySpouseMaintenanceSource"}
                  label={`SOURCE? i.e., Wages, social welfare, attachment of
                      earnings, earnings from other sources`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={"familySpouseMaintenanceArrears"}
                  label={`ARREARS? If so, how much to date?`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <Box sx={{ padding: "32px 0 16px 0" }}>
                  <Text sx={{ fontWeight: "bold" }}>
                    What are you looking for regarding the following aspects?
                    Please be specific about the arrangement you seek & state
                    amounts, dates, times where required.
                  </Text>
                </Box>

                <FormInput
                  id={"familyHouseSeeking"}
                  label={`House`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={"familyFinancialSettlementSeeking"}
                  label={`Financial Settlement`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={"familyCustodySeeking"}
                  label={`Custody of Children`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={"familyAccessSeeking"}
                  label={`Access with Children`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={"familyChildMaintSeeking"}
                  label={`Maintenance payment for children per week`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={"familySpouseMaintSeeking"}
                  label={` Maintenance payment for/from spouse per week`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />
              </Box>
              <Divider sx={{ margin: "16px 0", border: "1px solid #e8e8e8" }} />
            </Box>

            <Button
              onClick={handleSaveWithoutValidation}
              border="2px solid #c88a5d"
              color={"#c88a5d"}
              variant="outline"
              isDisabled={saving}
              mt={4}
            >
              {saving ? (
                <Spinner
                  thickness="1px"
                  speed="0.65s"
                  emptyColor="#fff"
                  color="#c88a5d"
                  size="md"
                />
              ) : (
                <Text>Save Family Home information for later</Text>
              )}
            </Button>
            <Button onClick={onSubmitForm} bg="#c88a5d" color={"#fff"} mt={4}>
              Complete Family Home information
            </Button>
          </>
        </Box>
      </form>
      <ConfirmSubmitModal onSubmit={handleSubmit(onFormConfirm)} />
    </>
  );
}
