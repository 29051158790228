import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Input,
  Text,
  Divider,
  Select,
  useToast,
  Spinner,
  useMediaQuery,
} from "@chakra-ui/react";
import { scrollToTop } from "utils/ScrollToTop";
import useStore from "store/store";
import FormInput from "components/inputs/FormInput";
import { AffidavitForm as AffidavitInterface } from "types/form";
import {
  createAffidavitFormData,
  fetchAndSetFormData,
  getAffidavitFormData,
} from "services/api/form";
import ConfirmSubmitModal from "components/modals/ConfirmSubmitModal";
import { IconFileCheck } from "@tabler/icons-react";

export default function AffidavitForm() {
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const toast = useToast();

  const {
    openSubmitModal,
    updateForm,
    selectedAffidavitForm,
    setSelectedAffidavitForm,
    forms,
    user,
    setApplicationIsComplete,
    addCompletedForm,
    formsCompleted,
    setUser,
  } = useStore();

  const {
    control,
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<AffidavitInterface>({
    defaultValues: selectedAffidavitForm,
  });

  const {
    fields: childrenFields,
    append: addChild,
    remove: removeChild,
  } = useFieldArray({
    control,
    name: "affiChildrenDetails",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "affiFinancialAccounts",
  });

  const isComplete = forms.find((form) => form.id === 5)?.isComplete;

  // Reset form when selectedClientForm changes
  useEffect(() => {
    if (selectedAffidavitForm) {
      reset(selectedAffidavitForm);
    }
  }, [selectedAffidavitForm, reset]);

  useEffect(() => {
    console.info("rendered");

    const fetchAffidavitFormData = async () => {
      if (user?.affidavitFormStatus === "saved") {
        try {
          setLoading(true); // Optional: if you want to manage loading state
          await fetchAndSetFormData(
            setSelectedAffidavitForm,
            getAffidavitFormData,
            user!
          );
          setLoading(false); // Optional: reset loading state after the data is fetched
        } catch (error) {
          setLoading(false); // Optional: reset loading state in case of an error
          console.error("Failed to fetch affidavit form data:", error);
        }
      }
    };

    fetchAffidavitFormData();
  }, [setSelectedAffidavitForm, user]);

  const handleSaveWithoutValidation = () => {
    const data = getValues();
    onSaveForm(data);
  };

  const onSaveForm = async (data: any) => {
    const { submission_id, submitted_at, ...rest } = data;
    const body = { ...rest, clientId: user?.userId };

    try {
      setSaving(true);
      const clientData = await createAffidavitFormData(body);

      setUser({
        ...user!,
        isLoggedIn: true,
        affidavitFormStatus: "saved",
      });
      setSelectedAffidavitForm(clientData);
      toast({
        title: "Saved form.",
        description: "You've successfully saved this information.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
      setSaving(false);
    } catch (err: any) {
      console.error("Failed to fetch client data:", err);
      setSaving(false);
      toast({
        title: "Error saving form.",
        description: "An error occurred while saving this form.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };

  const onSubmitForm = (data: any) => {
    handleSubmit(() => {
      if (Object.keys(errors).length === 0) {
        openSubmitModal();
      } else {
        scrollToTop();
      }
    })(data);
  };

  const onFormConfirm = async (data: any) => {
    const { submission_id, submitted_at, ...rest } = data;
    const body = { ...rest, clientId: user?.userId };

    try {
      setLoading(true);
      const clientData = await createAffidavitFormData(body);

      setUser({
        ...user!,
        isLoggedIn: true,
        affidavitFormStatus: "complete",
      });
      setSelectedAffidavitForm(clientData);
      updateForm({ id: 5, isComplete: true });
      toast({
        title: "Saved form.",
        description: "You've successfully completed this form.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
      setLoading(false);
      addCompletedForm();
      scrollToTop();
      if (formsCompleted >= 5) {
        setApplicationIsComplete();
      }
    } catch (err: any) {
      console.error("Failed to fetch client data:", err);
      setLoading(false);
      toast({
        title: "Error saving form.",
        description: "An error occurred while completing this form.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };

  // Helper function to safely extract the error message as a string
  const getErrorMessage = (error: any): string | null => {
    if (error && error.message) {
      return error.message;
    }
    return null;
  };

  const inputStyle = {
    padding: "4px",
    border: "1px solid #e2e8f0",
    borderRadius: "4px",
    background: "#f4f2ee",
  };

  if (loading) {
    return (
      <Box
        sx={{
          w: "100%",
          h: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="#c88a5d"
          size="xl"
        />
      </Box>
    );
  }

  if (
    isComplete ||
    user?.application_status === "complete" ||
    user?.affidavitFormStatus === "complete"
  ) {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", color: "#404040" }}>
        <Box sx={{ padding: "32px 0" }}>
          <Text sx={{ fontWeight: "bold", fontSize: "20px" }}>
            AFFIDAVIT INFORMATION
          </Text>
        </Box>
        <Box sx={{ display: "flex" }}>
          <IconFileCheck size={22} color="#37ba32" />
          <Text sx={{ ml: 2 }}>The Affidavit Information form is complete</Text>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <form>
        <Box
          sx={{ display: "flex", flexDirection: "column", color: "#404040" }}
        >
          <Box>
            <Box sx={{ padding: "32px 0" }}>
              <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>
                AFFIDAVIT OF MEANS
              </Text>
            </Box>

            <Text mb={2}>
              I,
              <Input
                id={`affiName`}
                style={inputStyle}
                placeholder="Your Name"
                {...register("affiName", {})}
                width="auto"
                mx={2}
                mb={isLargerThan600 ? 0 : 2}
                display="inline"
              />
              ,
              <Input
                id={`affiAddress`}
                style={inputStyle}
                placeholder="Your Address"
                {...register("affiAddress", {})}
                width="auto"
                mx={2}
                display="inline"
              />
              being aged 18 years and upwards MAKE OATH and SAY as follows:
            </Text>

            <Text mb={2}>
              1. I say that I am the Applicant in the above entitled proceedings
              and I make this Affidavit from facts within my own knowledge save
              where otherwise appears and where so appearing I believe the same
              to be true.
            </Text>

            <Text mb={2}>
              2. I say that I have set out in the First Schedule hereto all the
              assets to which I am legally or beneficially entitled and the
              manner in which such property is held.
            </Text>

            {/* <Textarea
              style={inputStyle}
              {...register("affiFirstSchedule", {
                required: "Field is required",
              })}
              mb={4}
            /> */}

            <Text mb={2}>
              3. I say that I have set out in the Second Schedule hereto all
              income which I receive and the source(s) of such income.
            </Text>

            {/* <Textarea
              style={inputStyle}
              {...register("affiSecondSchedule", {
                required: "Field is required",
              })}
              mb={4}
            /> */}

            <Text mb={2}>
              4. I say that I have set out in the Third Schedule hereto all my
              debts and/or liabilities and the persons to whom such debts and
              liabilities are due.
            </Text>

            {/* <Textarea
              style={inputStyle}
              {...register("affiThirdSchedule", {
                required: "Field is required",
              })}
              mb={4}
            /> */}

            <Text mb={2}>
              5. I say that my weekly outgoings amount to the sum of €
              <Input
                style={inputStyle}
                placeholder={"weekly outgoings"}
                {...register("affiWeeklyOutgoings", {})}
                width="auto"
                mx={2}
                display="inline"
                type="number"
              />
              and I say that the details of such outgoings have been set out in
              the Fourth Schedule hereto.
            </Text>

            {/* <Textarea
              style={inputStyle}
              {...register("affiFourthSchedule", {
                required: "Field is required",
              })}
              mb={4}
            /> */}

            <Text mb={2}>
              6. I say that to the best of my knowledge, information and belief,
              all pension information known to me relevant to the within
              proceedings is set out in the Fifth Schedule hereto.
            </Text>

            {/* <Textarea
              style={inputStyle}
              {...register("affiFifthSchedule", {
                required: "Field is required",
              })}
              mb={4}
            /> */}
          </Box>

          <Divider sx={{ margin: "32px 0", border: " 1px solid #e8e8e8" }} />

          <Box>
            <Box sx={{ padding: "32px 0" }}>
              <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>
                FIRST SCHEDULE - ASSETS
              </Text>
            </Box>

            <FormInput
              id={"affiFamilyHomeAddress"}
              label={`Address`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"affiFamilyHomeHeldIn"}
              label={`Held in the (joint) name(s) of`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"affiFamilyHomeMarketValue"}
              label={`Estimated market value (€)`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <FormInput
              id={"affiFamilyHomeMortgagee"}
              label={`Subject to a mortgage in favour of (mortgagee) in the sum of (€)`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <Divider sx={{ margin: "32px 0", border: " 1px solid #e8e8e8" }} />

            <Text fontSize="lg" mb={4}>
              2. Car
            </Text>

            <FormInput
              id={"affiCarMakeModel"}
              label={`Make/Model`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <Box mb={4}>
              <Text>Registered in the (solo/joint) name(s) of</Text>
              <Select
                style={inputStyle}
                placeholder="Select"
                {...register("affiCarRegistrationType", {})}
                mb={2}
              >
                <option value="solo">Solo</option>
                <option value="joint">Joint</option>
              </Select>
              {errors["affiCarRegistrationType"] && (
                <Text sx={{ fontSize: "11px", padding: "4px" }} color="red.500">
                  {getErrorMessage(errors["affiCarRegistrationType"])}
                </Text>
              )}
            </Box>

            <FormInput
              id={"affiCarMarketValue"}
              label={`Estimated market value (€)`}
              register={register}
              registerOptions={{
                maxLength: 80,
              }}
              type={"text"}
              errors={errors}
            />

            <Divider sx={{ margin: "32px 0", border: " 1px solid #e8e8e8" }} />

            <Text fontSize="lg" mb={4}>
              3. Accounts held with Financial Institutions
            </Text>
            <Box sx={{ padding: "16px 0" }}>
              <Text>
                Please list all accounts held in your name and all accounts held
                in joint names. Please give the name of financial institution
                and the account number.
              </Text>
            </Box>
            {fields.map((field, index) => (
              <Box key={field.id} mb={4}>
                <Text>Account {index + 1}</Text>

                <FormInput
                  id={`affiFinancialAccounts.${index}.institution`}
                  label={`Name of financial institution`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiFinancialAccounts.${index}.balance`}
                  label={`Balance (€)`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <Button
                  border="2px solid #ff4141"
                  color={"#ff4141"}
                  variant={"outlined"}
                  onClick={() => remove(index)}
                  mt={2}
                >
                  Remove Account
                </Button>
              </Box>
            ))}
            <Button
              bg="#c88a5d"
              color={"#fff"}
              onClick={() => append({ institution: "", balance: "" })}
            >
              Add Account
            </Button>
          </Box>

          <Divider sx={{ margin: "32px 0", border: " 1px solid #e8e8e8" }} />

          <Box>
            <Box>
              <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>
                SECOND SCHEDULE - INCOME
              </Text>

              <FormInput
                id={`affiSalary`}
                label={`Salary (net)`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />

              <FormInput
                id={`affiMaintenance`}
                label={`Maintenance`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />

              <FormInput
                id={`affiOtherIncome`}
                label={`Any other Income`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />
            </Box>

            <Divider sx={{ margin: "32px 0", border: " 1px solid #e8e8e8" }} />

            <Box>
              <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>
                THIRD SCHEDULE - DEBTS/LIABILITIES
              </Text>
              <Box sx={{ padding: "16px 0" }}>
                <Text>
                  Please list all mortgage account(s) and credit card account(s)
                  held in your name and all accounts held in joint names. Please
                  give the name of financial institution and the account number.
                </Text>
              </Box>

              <FormInput
                id={`affiMortgageAccountBalance`}
                label={`Mortgage Account (Balance)`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />

              <FormInput
                id={`affiCreditCardBalance`}
                label={`Credit Card (Balance)`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />

              <FormInput
                id={`affiLoans`}
                label={`Loans`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />
            </Box>

            <Divider sx={{ margin: "32px 0", border: " 1px solid #e8e8e8" }} />

            <Box>
              <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>
                FOURTH SCHEDULE - WEEKLY OUTGOINGS
              </Text>
              <Box sx={{ padding: "16px 0" }}>
                <Text>
                  Please include all your outgoings on a monthly basis. If an
                  expenditure occurs on a weekly basis/only once a year or a few
                  times a year, please specify. If you have any outgoing which
                  are not listed here, please include these and specify.
                </Text>
              </Box>

              <FormInput
                id={`affiShoppingGroceries`}
                label={`Shopping/Groceries (Food, Household/Cleaning
                Products/Toiletries, etc.) (€)`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />

              <FormInput
                id={`affiRentMortgageRepayments`}
                label={`Rent and/or Mortgage Repayments (€)`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />

              <FormInput
                id={`affiHouseholdRepairsMaintenance`}
                label={`Household Repairs/Maintenance (€)`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />

              <FormInput
                id={`affiHouseInsurance`}
                label={`House Insurance (€)`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />

              <FormInput
                id={`affiContentsInsurance`}
                label={`Contents Insurance (€)`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />

              <FormInput
                id={`affiTelephoneLandline`}
                label={`Telephone (landline) (€)`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />

              <FormInput
                id={`affiCableTelevision`}
                label={`Cable Television (€)`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />

              <FormInput
                id={`affiInternetBroadband`}
                label={`Internet/Broadband (€)`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />

              <FormInput
                id={`affiTelephoneTvInternetCombined`}
                label={`Or: Telephone/TV/Internet combined (e.g., UPC) €`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />

              <FormInput
                id={`affiTelevisionLicense`}
                label={`Television License (€)`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />

              <FormInput
                id={`affiElectricity`}
                label={`Electricity (€)`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />

              <FormInput
                id={`affiHeating`}
                label={`Heating (Gas, etc.) (€)`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                type={"text"}
                errors={errors}
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>
                  Charges (€):
                </Text>

                <FormInput
                  id={`affiChargesWaterRates`}
                  label={`Water Rates`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesRefuse`}
                  label={`Refuse`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesGardening`}
                  label={`Gardening`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesMobilePhone`}
                  label={`Mobile Phone`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesPetrol`}
                  label={`Petrol`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesCarInsurance`}
                  label={`Car Insurance`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesCarTax`}
                  label={`Car Tax`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesCarMaintenance`}
                  label={`Car Maintenance`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesAAMembership`}
                  label={`AA Membership`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesPublicTransportSelf`}
                  label={`Public Transport (self)`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesHealthInsurance`}
                  label={`Health Insurance (Please Give name of Provider € and specify who is insured, i.e. self/spouse/children)`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesMedicalExpenseNotCovered`}
                  label={`Medical Expense € Not covered by Health Insurance (Please Specify)`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesDentist`}
                  label={`Dentist (self/children)`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesPharmacyExpenses`}
                  label={`Pharmacy Expenses (self/children)`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesSchoolFees`}
                  label={`School Fees`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesSchoolUniforms`}
                  label={`School Uniforms`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesSchoolBooks`}
                  label={`School Books`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesSportOtherActivities`}
                  label={`Sport/Other Activities for Children (Please specify)`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesSchoolTours`}
                  label={`School Tours`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesPocketMoneyPresents`}
                  label={`Pocket Money/presents/Birthdays/Christmas € (Children)`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesChildrensClothing`}
                  label={`Clothing for Children`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesCrecheBabysitterChildminder`}
                  label={`Crèche/ Babysitter/ Childminder (Please specify)`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesLoanRepayments`}
                  label={`Loan repayments (Please specify)`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesCreditCardPayments`}
                  label={`Credit Card payments`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesLifeAssurance`}
                  label={`Life Assurance`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesHirePurchaseRepayments`}
                  label={`Hire Purchase repayments`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesHolidaysDayOutings`}
                  label={`Holidays/ Day Outings`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesHairdresserSelfChildren`}
                  label={`Hairdresser (Self/children)`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesClothingAndShoesSelf`}
                  label={`Clothing and Shoes (Self)`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesMembershipFeesClubs`}
                  label={`Membership Fees/Clubs (Please specify)`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesHobbies`}
                  label={`Hobbies (Please specify)`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesAdultEducationalClasses`}
                  label={`Adult Educational Classes (Please specify)`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesGiftsBirthdaysChristmas`}
                  label={`Gifts/Birthdays/Christmas`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesEntertainment`}
                  label={`Entertainment – Cinema, Theatre, etc. (Self/children)`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesMiscellaneous`}
                  label={`Miscellaneous: Dry Cleaning, newspapers, books`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />

                <FormInput
                  id={`affiChargesOther`}
                  label={`Other (Please specify)`}
                  register={register}
                  registerOptions={{
                    maxLength: 80,
                  }}
                  type={"text"}
                  errors={errors}
                />
              </Box>
            </Box>

            <Divider sx={{ margin: "32px 0", border: " 1px solid #e8e8e8" }} />

            <Box>
              <Text pb={"16px"} sx={{ fontWeight: "bold", fontSize: "18px" }}>
                FIFTH SCHEDULE - PENSION INFORMATION
              </Text>

              <FormInput
                id={`affiFifthScheduleDetails`}
                label={`Please provide details of your pension(s) (i.e., nature, benefit payable, normal pensionable age, period of reckonable service).`}
                register={register}
                registerOptions={{
                  maxLength: 80,
                }}
                isTextarea
                type={"text"}
                errors={errors}
              />
            </Box>
          </Box>

          <Divider sx={{ margin: "32px 0", border: " 1px solid #e8e8e8" }} />

          <Box sx={{ padding: "32px 0" }}>
            <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>
              AFFIDAVIT OF WELFARE
            </Text>
          </Box>

          <Text mb={2}>
            I,
            <Input
              style={inputStyle}
              placeholder="Your Name"
              {...register("affiNametwo", {})}
              width="auto"
              mx={2}
              display="inline"
            />
            ,
            <Input
              style={inputStyle}
              placeholder="Your Age"
              {...register("affiAge", {
                min: { value: 18, message: "Age must be at least 18" },
              })}
              width="auto"
              mx={2}
              display="inline"
              type="number"
            />
            being aged 18 years and upwards MAKE OATH and SAY as follows:
          </Text>

          <Text mb={2}>
            1. I say that I am the Applicant in the above entitled proceedings
            and I make this Affidavit from facts within my own knowledge save
            where otherwise appears and where so appearing I believe the same to
            be true.
          </Text>

          <Text mb={2}>
            2. I say and believe that the facts set out in the Schedule hereto
            are true.
          </Text>

          {/* <FormInput
            id={`affiScheduleDetails`}
            label={`Enter details for the schedule`}
            register={register}
            registerOptions={{
              required: "Field is required",
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          /> */}

          <Divider sx={{ margin: "32px 0", border: " 1px solid #e8e8e8" }} />

          <Box sx={{ padding: "32px 0" }}>
            <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>SCHEDULE</Text>
            <Text
              sx={{ fontWeight: "bold", fontSize: "16px", marginTop: "16px" }}
            >
              Part I - Details of the Children
            </Text>
          </Box>

          <Text mb={2}>
            1. Details of children born to the Respondent and the Applicant or
            adopted by both the Plaintiff and the Defendant:
          </Text>

          {childrenFields.map((child, index) => (
            <Box
              key={child.id}
              sx={{
                display: "flex",
                flexDirection: isLargerThan600 ? "row" : "column",
                marginBottom: "16px",
              }}
            >
              <Input
                style={inputStyle}
                placeholder="Forenames"
                {...register(`affiChildrenDetails.${index}.forenames`, {})}
                width={isLargerThan600 ? "30%" : "100%"}
                mr={isLargerThan600 ? 2 : 0}
                mb={isLargerThan600 ? 0 : 2}
              />
              <Input
                style={inputStyle}
                placeholder="Surname"
                {...register(`affiChildrenDetails.${index}.surname`, {})}
                width={isLargerThan600 ? "30%" : "100%"}
                mr={isLargerThan600 ? 2 : 0}
                mb={isLargerThan600 ? 0 : 2}
              />
              <Input
                style={inputStyle}
                placeholder="Date of Birth"
                {...register(`affiChildrenDetails.${index}.dob`, {})}
                width={isLargerThan600 ? "40%" : "100%"}
                mr={isLargerThan600 ? 2 : 0}
                mb={isLargerThan600 ? 0 : 2}
                type="date"
              />
              <Button
                ml={isLargerThan600 ? 2 : 0}
                width={isLargerThan600 ? "20%" : "30%"}
                border="2px solid #ff4141"
                color={"#ff4141"}
                variant={"outlined"}
                onClick={() => removeChild(index)}
              >
                Remove
              </Button>
            </Box>
          ))}

          <Box mb={4}>
            <Button
              bg="#c88a5d"
              color={"#fff"}
              onClick={() => addChild({ forenames: "", surname: "", dob: "" })}
            >
              Add Child
            </Button>
          </Box>

          <Text mb={2}>
            2. Details of other children of the family or to which the parties
            or either of them are in loco parentis.
          </Text>
          <FormInput
            id={`affiOtherChildrenDetails`}
            label={`Enter details of other children here`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Divider sx={{ margin: "32px 0", border: "1px solid #e8e8e8" }} />

          <Box sx={{ padding: "32px 0" }}>
            <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>SCHEDULE</Text>
            <Text
              sx={{ fontWeight: "bold", fontSize: "16px", marginTop: "16px" }}
            >
              Part II - Arrangements for the children of the family
            </Text>
          </Box>

          <Text mb={2}>4. Home Details:</Text>

          <Text mb={2}>
            (a) The address or addresses at which the children now live.
          </Text>
          <FormInput
            id={`affiChildrenAddresses`}
            label={`Enter the addresses where the children now live`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Text mb={2}>
            (b) Give details of number of living rooms, bedrooms etc. at the
            address(es) in (a) above.
          </Text>
          <FormInput
            id={`affiRoomsDetails`}
            label={`Enter details of living rooms, bedrooms, etc.`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Text mb={2}>
            (c) Is the house rented or owned and, if so, name the tenant(s) or
            owner(s)?
          </Text>
          <FormInput
            id={`affiOwnershipDetails`}
            label={`Specify if the house is rented or owned, and provide the names of the tenant(s) or owner(s)`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Text mb={2}>
            (d) Is the rent or mortgage being regularly paid and, if so, by
            whom?
          </Text>
          <FormInput
            id={`affiRoomsDetails`}
            label={`Enter details of living rooms, bedrooms, etc.`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <FormInput
            id={`affiPaymentDetails`}
            label={`Provide details about rent or mortgage payments and who is responsible for them`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Text mb={2}>
            (e) Give names of all other persons living with the children either
            on a full-time or part-time basis and state their relationship to
            the children, if any.
          </Text>
          <FormInput
            id={`affiOtherPersonsDetails`}
            label={`List all other persons living with the children and their relationship to the children`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Text mb={2}>
            (f) Will there be any change in these arrangements and, if so, give
            details.
          </Text>
          <FormInput
            id={`affiChangesDetails`}
            label={`Specify if there will be any changes in these arrangements and provide details`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Divider sx={{ margin: "32px 0", border: "1px solid #e8e8e8" }} />

          <Box sx={{ padding: "32px 0" }}>
            <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>SCHEDULE</Text>
            <Text
              sx={{ fontWeight: "bold", fontSize: "16px", marginTop: "16px" }}
            >
              Part III - Education and Training Details
            </Text>
          </Box>

          <Text mb={2}>
            (a) Give names of the school, college or place of training attended
            by each child.
          </Text>
          <FormInput
            id={`affiEducationDetails`}
            label={`Enter the names of the school, college, or place of training attended by each child`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Text mb={2}>
            (b) Do the children have any special education needs? If so, please
            specify.
          </Text>
          <FormInput
            id={`affiSpecialEducationNeeds`}
            label={`Specify any special education needs for the children`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Text mb={2}>
            (c) Is the school, college or place of training fee-paying? If so,
            give details on cost per term/year. Are fees being paid regularly
            and, if so, by whom?
          </Text>
          <FormInput
            id={`affiFeesDetails`}
            label={`Provide details on fees, costs per term/year, and who is paying them`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Text mb={2}>
            (d) Will there be any change in these arrangements and, if so, give
            details.
          </Text>
          <FormInput
            id={`affiChangesInEducation`}
            label={`Specify any changes in the current education and training arrangements`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Divider sx={{ margin: "32px 0", border: "1px solid #e8e8e8" }} />

          <Box sx={{ padding: "32px 0" }}>
            <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>SCHEDULE</Text>
            <Text
              sx={{ fontWeight: "bold", fontSize: "16px", marginTop: "16px" }}
            >
              Part IV - Childcare Details
            </Text>
          </Box>

          <Text mb={2}>
            (a) Which parent looks after the children from day to day? If
            responsibility is shared, please give details.
          </Text>
          <FormInput
            id={`affiDayToDayCare`}
            label={`Enter details of which parent looks after the children from day to day`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Text mb={2}>
            (b) Give details of work commitments of both parents.
          </Text>
          <FormInput
            id={`affiWorkCommitments`}
            label={`Provide details of work commitments for both parents`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Text mb={2}>
            (c) Does someone look after the children when the parent is not
            there? If yes, give details.
          </Text>
          <FormInput
            id={`affiOtherCarerDetails`}
            label={`Provide details if someone else looks after the children when the parent is not available`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Text mb={2}>
            (d) Who looks after the children during school holidays?
          </Text>
          <FormInput
            id={`affiHolidayCareDetails`}
            label={`Enter details of who looks after the children during school holidays`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Text mb={2}>
            (e) Will there be any change in these arrangements? If yes, give
            details.
          </Text>
          <FormInput
            id={`affiChangesInChildcare`}
            label={`Specify if there will be any changes in these childcare arrangements`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Divider sx={{ margin: "32px 0", border: "1px solid #e8e8e8" }} />

          <Box sx={{ padding: "32px 0" }}>
            <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>SCHEDULE</Text>
            <Text
              sx={{ fontWeight: "bold", fontSize: "16px", marginTop: "16px" }}
            >
              Part V - Maintenance
            </Text>
          </Box>

          <Text mb={2}>
            (a) Does Applicant pay towards the upkeep of the children? If yes,
            give details. Please specify any other source of maintenance.
          </Text>
          <FormInput
            id={`affiUpkeepDetails`}
            label={`Enter details about the applicant's payments towards the upkeep of the children and any other sources of maintenance`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Text mb={2}>
            (b) Is the maintenance referred to (a) above paid under court order?
            If yes, give details.
          </Text>
          <FormInput
            id={`affiCourtOrderDetails`}
            label={`Provide details if the maintenance is paid under court order`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Text mb={2}>
            (c) Has maintenance for the children been agreed? If yes, give
            details.
          </Text>
          <FormInput
            id={`affiMaintenanceAgreementDetails`}
            label={`Specify if maintenance for the children has been agreed and provide details`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Text mb={2}>
            (d) If not, will you be applying for a maintenance order from the
            court?
          </Text>
          <FormInput
            id={`affiMaintenanceOrderApplication`}
            label={`Specify if you will be applying for a maintenance order from the court`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Divider sx={{ margin: "32px 0", border: "1px solid #e8e8e8" }} />

          <Box sx={{ padding: "32px 0" }}>
            <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>SCHEDULE</Text>
            <Text
              sx={{ fontWeight: "bold", fontSize: "16px", marginTop: "16px" }}
            >
              Part VI - Details of Contact with Children
            </Text>
          </Box>

          <Text mb={2}>
            (a) Do the children see the Applicant? Please give details.
          </Text>
          <FormInput
            id={`affiContactWithApplicant`}
            label={`Provide details about the children's contact with the Applicant`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Text mb={2}>
            (b) Do the children stay overnight and/or have holiday visits with
            the Plaintiff/Defendant? Please give details.
          </Text>
          <FormInput
            id={`affiOvernightHolidayDetails`}
            label={`Provide details about overnight stays and/or holiday visits with the Plaintiff/Defendant`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Text mb={2}>
            (c) Will there be any change to these arrangements? If yes, give
            details.
          </Text>
          <FormInput
            id={`affiChangesToContact`}
            label={`Specify if there will be any changes to the current contact arrangements`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Divider sx={{ margin: "32px 0", border: "1px solid #e8e8e8" }} />

          <Box sx={{ padding: "32px 0" }}>
            <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>SCHEDULE</Text>
            <Text
              sx={{ fontWeight: "bold", fontSize: "16px", marginTop: "16px" }}
            >
              Part VII - Details of Health
            </Text>
          </Box>

          <Text mb={2}>
            (a) Are the children generally in good health? Please give details
            of any serious disability or chronic illness suffered by any of the
            children.
          </Text>
          <FormInput
            id={`affiChildrenHealthDetails`}
            label={`Provide details about the children's general health, including any serious disability or chronic illness`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Text mb={2}>
            (b) Do the children or any of them have any special health needs?
            Please give details of the care needed and how it is to be provided.
          </Text>
          <FormInput
            id={`affiSpecialHealthNeeds`}
            label={`Provide details of any special health needs and the care required`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Text mb={2}>
            (c) Are the Applicant or Respondent generally in good health? If
            not, please give details.
          </Text>
          <FormInput
            id={`affiApplicantRespondentHealth`}
            label={`Provide details about the general health of the Applicant or Respondent`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Divider sx={{ margin: "32px 0", border: "1px solid #e8e8e8" }} />

          <Box sx={{ padding: "32px 0" }}>
            <Text sx={{ fontWeight: "bold", fontSize: "18px" }}>SCHEDULE</Text>
            <Text
              sx={{ fontWeight: "bold", fontSize: "16px", marginTop: "16px" }}
            >
              Part VIII - Details of Care and Other Court Proceedings
            </Text>
          </Box>

          <Text mb={2}>
            (a) Are the children or any of them in the care of a Health Board or
            under the supervision of a Social Worker or Probation Officer? If
            so, please specify.
          </Text>
          <FormInput
            id={`affiHealthBoardOrSupervision`}
            label={`Provide details if the children are in the care of a Health Board or under supervision`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Text mb={2}>
            (b) Are there or have there been any proceedings in any Court
            involving the children or any of them? If so, please specify. All
            relevant Court Orders relating to the children or any of them should
            be annexed hereto.
          </Text>
          <FormInput
            id={`affiCourtProceedingsDetails`}
            label={`Provide details of any Court proceedings involving the children, including any relevant Court Orders`}
            register={register}
            registerOptions={{
              maxLength: 80,
            }}
            isTextarea
            type={"text"}
            errors={errors}
          />

          <Divider sx={{ margin: "32px 0", border: "1px solid #e8e8e8" }} />

          <Button
            onClick={handleSaveWithoutValidation}
            border="2px solid #c88a5d"
            color={"#c88a5d"}
            variant="outline"
            isDisabled={saving}
            mt={4}
          >
            {saving ? (
              <Spinner
                thickness="1px"
                speed="0.65s"
                emptyColor="gray.200"
                color="#c88a5d"
                size="md"
              />
            ) : (
              <Text>Save Affidavit for later</Text>
            )}
          </Button>
          <Button
            onClick={handleSubmit(onSubmitForm)}
            bg="#c88a5d"
            color={"#fff"}
            mt={4}
          >
            Complete Affidavit
          </Button>
        </Box>
      </form>
      <ConfirmSubmitModal onSubmit={handleSubmit(onFormConfirm)} />
    </>
  );
}
