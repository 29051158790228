import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import Footer from "components/navigation/footer";
import Navbar from "components/navigation/navbar/Navbar";
import SideMenu from "components/navigation/side-menu/SideMenu";
import Sidebar from "components/navigation/sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import {
  IconLayoutDashboard,
  IconTemplate,
  IconUsers,
} from "@tabler/icons-react";

interface SidebarLinkProps {
  id: number;
  title: string;
  path: string;
  gap?: boolean;
  icon: React.ReactNode;
}

const menuLinks: SidebarLinkProps[] = [
  {
    id: 1,
    title: "Dashboard",
    path: "/dashboard",
    icon: <IconLayoutDashboard stroke={1} />,
  },
  {
    id: 2,
    title: "Template",
    path: "/dashboard/template",
    icon: <IconTemplate stroke={1} />,
  },
  {
    id: 3,
    title: "Clients",
    path: "/dashboard/clients",
    icon: <IconUsers stroke={1} />,
  },
];

const DashboardLayout = () => {
  const Main = styled(Box)(() => ({
    display: "flex",
    overflow: "hidden",
    minHeight: "calc(100vh - 160px)",
  }));

  return (
    <>
      <Navbar />
      <SideMenu />
      <Main>
        <Sidebar links={menuLinks} title={"Dashboard"} />
        <Outlet />
      </Main>
      <Footer />
    </>
  );
};

export default DashboardLayout;
