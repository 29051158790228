import { Box, useMediaQuery } from "@chakra-ui/react";
import styled from "@emotion/styled";
import Footer from "components/navigation/footer";
import Navbar from "components/navigation/navbar/Navbar";
import SideMenu from "components/navigation/side-menu/SideMenu";
import Sidebar from "components/navigation/sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import { SidebarLink } from "types/sidebar";
import {
  IconUsersGroup,
  IconBriefcase,
  IconFiles,
  IconClipboard,
  IconFileCheck,
  IconCertificate,
} from "@tabler/icons-react";

const menuLinks: SidebarLink[] = [
  {
    id: 1,
    title: "Client Information",
    path: "/forms",
    icon: <IconFiles stroke={1} />,
  },
  {
    id: 2,
    title: "Spouse Information",
    path: "/forms/spouse-information",
    icon: <IconClipboard stroke={1} />,
  },
  {
    id: 3,
    title: "Marriage Details",
    path: "/forms/marriage",
    icon: <IconBriefcase stroke={1} />,
  },
  {
    id: 4,
    title: "Family Home",
    path: "/forms/family-home",
    icon: <IconUsersGroup stroke={1} />,
  },
  {
    id: 5,
    title: "Affidavit",
    path: "/forms/affidavit",
    icon: <IconCertificate stroke={1} />,
  },
  {
    id: 6,
    title: "Complete Application",
    path: "/forms/complete-application",
    icon: <IconFileCheck stroke={1} />,
  },
];

const UserLayout = () => {
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");

  const Main = styled(Box)(() => ({
    display: "flex",
    overflow: "hidden",
    minHeight: "calc(100vh - 160px)",
  }));

  return (
    <>
      <Navbar />
      <SideMenu />
      <Main>
        <Sidebar links={menuLinks} title={"FORMS"} />
        <Box
          sx={{
            w: "100%",
            p: isLargerThan600 ? "0 128px 32px 128px" : "0 16px 32px 16px",
          }}
        >
          <Outlet />
        </Box>
      </Main>
      <Footer />
    </>
  );
};

export default UserLayout;
