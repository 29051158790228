const year = new Date().getFullYear();

export const constants = {
  navbar: {
    height: "80px",
    title: "Divorce Paper Solutions Limited",
  },
  footer: {
    height: "80px",
    copyright: `© ${year} DIY Divorce Paper Solutions Ltd. – All Rights Reserved.`,
  },
};
