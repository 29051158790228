import { Box, Input, FormLabel, Text } from "@chakra-ui/react";
import { useMemo } from "react";

interface ChildInputProps {
  control: any;
  register: any;
  childIndex: number;
  errors: any;
  id: string;
  title: string;
}

const ChildInput = ({
  register,
  childIndex,
  errors,
  id,
  title,
}: ChildInputProps) => {
  const formLabelStyle = useMemo(() => ({ fontWeight: 400 }), []);
  const inputStyle = useMemo(
    () => ({
      padding: "4px",
      border: "1px solid #e2e8f0",
      borderRadius: "4px",
      background: "#f4f2ee",
    }),
    []
  );

  return (
    <Box sx={{ padding: "16px 0" }}>
      <Text sx={{ fontWeight: "bold" }}>{`${title} ${childIndex + 1}`}</Text>

      <Box sx={{ padding: "8px 0", display: "flex", flexDirection: "column" }}>
        <FormLabel sx={formLabelStyle}>Name</FormLabel>
        <Input
          sx={inputStyle}
          {...register(`${id}.name`, {
            required: "Name is required",
          })}
          type="text"
          isInvalid={!!errors[`${id}`]?.name}
        />
        {errors[`${id}`]?.name && (
          <Text color="red">{errors[`${id}`]?.name.message}</Text>
        )}
      </Box>

      <Box sx={{ padding: "8px 0", display: "flex", flexDirection: "column" }}>
        <FormLabel sx={formLabelStyle}>Date of Birth</FormLabel>
        <Input
          sx={inputStyle}
          {...register(`${id}.dob`, {
            required: "DOB is required",
          })}
          type="date"
          isInvalid={!!errors[`${id}`]?.dob}
        />
        {errors[`${id}`]?.dob && (
          <Text color="red">{errors[`${id}`]?.dob.message}</Text>
        )}
      </Box>

      <Box sx={{ padding: "8px 0", display: "flex", flexDirection: "column" }}>
        <FormLabel sx={formLabelStyle}>Schools</FormLabel>
        <Input
          sx={inputStyle}
          {...register(`${id}.schools`, {
            required: "School is required",
          })}
          type="text"
          isInvalid={!!errors[`${id}`]?.schools}
        />
        {errors[`${id}`]?.schools && (
          <Text color="red">{errors[`${id}`]?.schools.message}</Text>
        )}
      </Box>
    </Box>
  );
};

export default ChildInput;
