import {
  Box,
  SimpleGrid,
  Spinner,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { IconUsers, IconChecklist, IconFilesOff } from "@tabler/icons-react";
import useStore from "store/store";
import { getUsers } from "services/api/user";
import { useEffect, useState } from "react";
import { User } from "types/user";

const Dashboard = () => {
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");
  const { users, setUsers } = useStore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAllUsers = async () => {
      setLoading(true);
      const users = await getUsers();
      setUsers(users);
      setLoading(false);
    };
    getAllUsers();
  }, [setUsers]);

  const clients = users.filter((user) => user.role !== "admin");
  const numCompleted = clients.filter(
    (client: User) => client.application_status === "complete"
  ).length;
  const numIncomplete = clients.length - numCompleted;

  if (loading) {
    return (
      <Box
        sx={{
          w: "100%",
          h: "calc(100vh - 160px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="#c88a5d"
          size="xl"
        />
      </Box>
    );
  }

  return (
    <Box sx={{ p: "32px 64px", width: "100%", bg: "#f2f4f7" }}>
      <SimpleGrid
        spacing={4}
        w={"100%"}
        templateColumns={isLargerThan600 ? "1fr 1fr 1fr" : "1fr"}
      >
        <Box
          sx={{
            border: "1px solid #dfdeda",
            borderRadius: "12px",
            p: "16px",
            bg: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Text sx={{ pb: 2, fontSize: "20px" }}>{clients.length}</Text>
          <IconUsers
            style={{ marginBottom: "8px" }}
            stroke={1}
            size={38}
            color="#c88a5d"
          />
          <Text sx={{ fontSize: "14px", textAlign: "center" }}>
            Total Clients
          </Text>
        </Box>
        <Box
          sx={{
            border: "1px solid #dfdeda",
            borderRadius: "12px",
            p: "16px",
            bg: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Text sx={{ pb: 2, fontSize: "20px" }}>{numIncomplete}</Text>
          <IconFilesOff
            style={{ marginBottom: "8px" }}
            stroke={1}
            size={38}
            color="#c88a5d"
          />
          <Text sx={{ fontSize: "14px", textAlign: "center" }}>
            Incomplete Applications
          </Text>
        </Box>
        <Box
          sx={{
            border: "1px solid #dfdeda",
            borderRadius: "12px",
            p: "16px",
            bg: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Text sx={{ pb: 2, fontSize: "20px" }}>{numCompleted}</Text>
          <IconChecklist
            style={{ marginBottom: "8px" }}
            stroke={1}
            size={38}
            color="#c88a5d"
          />
          <Text sx={{ fontSize: "14px", textAlign: "center" }}>
            Completed Applications
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default Dashboard;
