import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Box,
  Text,
  Tooltip,
  useMediaQuery,
  Spinner,
  useToast,
  Select,
} from "@chakra-ui/react";
import { ADMIN_API_URL } from "config";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteUser, getUsers } from "services/api/user";
import useStore from "store/store";

import {
  IconEye,
  IconCloudDownload,
  IconProgress,
  IconTrash,
  IconCircleCheck,
  IconRepeat,
} from "@tabler/icons-react";
import { User } from "types/user";
import ConfirmDeleteModal from "components/modals/ConfirmDeleteModal";
import { getGenerateCientDocument } from "services/api/document";

const Clients = () => {
  const [loading, setLoading] = useState(false);
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");
  const { setUsers, users, openDeleteModal, removeUser } = useStore();
  const toast = useToast();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [selectedClient, setSelectedClient] = useState<User | null>(null);
  const [downloadingClientId, setDownloadingClientId] = useState<string | null>(
    null
  );
  const [recreatingClientId, setRecreatingClientId] = useState<string | null>(
    null
  );
  const [deletingClientId, setDeletingClientId] = useState<string | null>(null);

  const clients = users.filter((user) => user.role !== "admin");

  // Calculate total pages
  const totalPages = Math.ceil(clients.length / itemsPerPage);

  // Function to change the page
  const handlePageChange = (newPage: number) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  // Function to handle change in items per page
  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to the first page when items per page is changed
  };

  // Slicing the items for the current page
  const currentItems = clients.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    console.info("rendered");
    async function fetchAndSetUsers() {
      try {
        setLoading(true);
        const users = await getUsers();
        setLoading(false);
        setUsers(users);
      } catch (error) {
        setLoading(false);
        console.error("Failed to fetch users:", error);
      }
    }
    fetchAndSetUsers();
  }, [setUsers]);

  const downloadFile = async (clientId: string) => {
    try {
      setDownloadingClientId(clientId); // Start loading for this client
      const response = await fetch(
        `${ADMIN_API_URL}/download-document?clientId=${clientId}`
      );
      const data = await response.json();

      setDownloadingClientId(null); // Stop loading after the action is done

      if (data.url) {
        window.location.href = data.url;
        toast({
          title: "Downloading file.",
          description: "Successfully downloaded the file.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom-right",
        });
      } else {
        console.error("Failed to get download URL");
      }
    } catch (error) {
      setDownloadingClientId(null); // Stop loading in case of an error
      toast({
        title: "Error downloading file.",
        description: "An error occurred while downloading the file.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
      console.error("Error:", error);
    }
  };

  const onDeleteConfirm = async (client: User) => {
    try {
      setDeletingClientId(client.userId); // Start loading for this client
      await deleteUser({ email: client.email, clientId: client.userId });
      setDeletingClientId(null); // Stop loading after the action is done
      removeUser(client);
      const users = await getUsers();
      setUsers(users);
      toast({
        title: "Deleted user.",
        description: "You've successfully deleted the user.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
    } catch (e) {
      setDeletingClientId(null); // Stop loading in case of an error
      toast({
        title: "Delete user",
        description: "Error trying to delete the user.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
      console.error("Error:", e);
    }
  };

  const regenerateDoc = async (userId: string, userEmail: string) => {
    try {
      setRecreatingClientId(userId); // Start loading for this client
      await getGenerateCientDocument(userId, userEmail);
      setRecreatingClientId(null); // Stop loading after the action is done
      toast({
        title: "Document recreated",
        description: "You've successfully recreated the document.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
    } catch (err) {
      setRecreatingClientId(null); // Stop loading in case of an error
      toast({
        title: "Error recreating the document.",
        description: "An error occurred while recreating the document.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
      console.error("Error: ", err);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          w: "100%",
          h: "calc(100vh - 160px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="#c88a5d"
          size="xl"
        />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ p: isLargerThan600 ? "32px" : "16px", width: "100%" }}>
        <Box sx={{ p: "32px 16px" }}>
          <Text sx={{ fontSize: "26px", fontWeight: "bold" }}>Clients</Text>
        </Box>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th sx={{ p: isLargerThan600 ? "12px 24px" : "12px" }}>Name</Th>
                <Th sx={{ p: isLargerThan600 ? "12px 24px" : "12px" }}>
                  {isLargerThan600 ? "Application Status" : "Status"}
                </Th>
                <Th
                  sx={{ p: isLargerThan600 ? "12px 24px" : "12px" }}
                  textAlign="right"
                >
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentItems.map((client: User, index: number) => {
                return (
                  <>
                    <Tr sx={{}} key={index}>
                      <Td
                        sx={{
                          fontSize: isLargerThan600 ? "14px" : "12px",
                          p: isLargerThan600 ? "16px 24px" : "8px",
                        }}
                      >
                        {client.name}
                      </Td>
                      <Td
                        sx={{
                          fontSize: isLargerThan600 ? "14px" : "12px",
                          p: isLargerThan600 ? "16px 24px" : "8px",
                        }}
                      >
                        {client.application_status === "inProgress" && (
                          <Box display={"Flex"} alignItems={"center"}>
                            <IconProgress color="orange" />
                            {isLargerThan600 && <Text ml={2}>In Progress</Text>}
                          </Box>
                        )}

                        {client.application_status === "complete" && (
                          <Box
                            display={"Flex"}
                            alignItems={"center"}
                            justifyContent={
                              isLargerThan600 ? "start" : "center"
                            }
                          >
                            <IconCircleCheck color="green" />
                            {isLargerThan600 && <Text ml={2}>Complete</Text>}
                          </Box>
                        )}
                      </Td>
                      <Td
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          fontSize: isLargerThan600 ? "14px" : "12px",
                          p: isLargerThan600 ? "16px 24px" : "8px",
                        }}
                      >
                        {isLargerThan600 && (
                          <Tooltip
                            hasArrow
                            label="Recreate Document"
                            aria-label="A tooltip"
                            bg="gray.300"
                            color="black"
                          >
                            <Button
                              sx={{
                                ml: 2,
                                p: isLargerThan600 ? "16px" : "8px",
                              }}
                              onClick={() =>
                                regenerateDoc(client.userId, client.email)
                              }
                              isDisabled={recreatingClientId === client.userId}
                            >
                              {recreatingClientId === client.userId ? (
                                <Spinner
                                  thickness="1px"
                                  speed="0.65s"
                                  emptyColor="gray.200"
                                  color="#c88a5d"
                                  size="md"
                                />
                              ) : (
                                <IconRepeat size={22} />
                              )}
                            </Button>
                          </Tooltip>
                        )}

                        <Tooltip
                          hasArrow
                          label="View Details"
                          aria-label="A tooltip"
                          bg="gray.300"
                          color="black"
                        >
                          <Button
                            sx={{
                              ml: 2,
                              p: isLargerThan600 ? "16px" : "8px",
                            }}
                            onClick={() =>
                              navigate("/dashboard/client-details", {
                                state: { userId: client.userId },
                              })
                            }
                          >
                            <IconEye size={22} />
                          </Button>
                        </Tooltip>
                        <Tooltip
                          hasArrow
                          label="Download Document"
                          aria-label="A tooltip"
                          bg="gray.300"
                          color="black"
                        >
                          <Button
                            isDisabled={
                              downloadingClientId === client.userId ||
                              client.application_status === "inProgress"
                            }
                            onClick={() => downloadFile(client.userId)}
                            sx={{
                              ml: 2,
                              p: isLargerThan600 ? "16px" : "8px",
                            }}
                          >
                            {downloadingClientId === client.userId ? (
                              <Spinner
                                thickness="1px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="#c88a5d"
                                size="md"
                              />
                            ) : (
                              <IconCloudDownload size={22} />
                            )}
                          </Button>
                        </Tooltip>
                        <Tooltip
                          hasArrow
                          label="Delete User"
                          aria-label="A tooltip"
                          bg="gray.300"
                          color="black"
                        >
                          <Button
                            variant={"outline"}
                            isDisabled={deletingClientId === client.userId}
                            onClick={() => {
                              setSelectedClient(client);
                              openDeleteModal();
                            }}
                            sx={{
                              ml: 2,
                              p: isLargerThan600 ? "16px" : "8px",
                            }}
                          >
                            {deletingClientId === client.userId ? (
                              <Spinner
                                thickness="1px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="#c88a5d"
                                size="md"
                              />
                            ) : (
                              <IconTrash color="red" size={22} />
                            )}
                          </Button>
                        </Tooltip>
                      </Td>
                    </Tr>
                  </>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <Box
          display={"flex"}
          flexDirection={isLargerThan600 ? "row" : "column"}
          justifyContent={"space-between"}
          mt={5}
        >
          <Box display={"flex"} alignItems={"center"} mb={4}>
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Text sx={{ p: "0 16px" }}>
              Page {currentPage} of {totalPages}
            </Text>
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </Box>

          <Box display={"flex"} alignItems={"center"} mb={4}>
            <Text sx={{ pr: "16px" }}>Items per page:</Text>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              width="120px"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </Select>
          </Box>
        </Box>
      </Box>
      <ConfirmDeleteModal onDelete={() => onDeleteConfirm(selectedClient!)} />
    </>
  );
};

export default Clients;
