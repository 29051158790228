import { USER_API_URL } from "config";
import {
  AffidavitForm,
  ClientForm,
  CompleteForms,
  FamilyForm,
  MarriageForm,
  SpouseForm,
} from "types/form";

import httpClient from "./httpClient";
import { User } from "types/user";

export function getClientFormData(clientId: string): Promise<ClientForm> {
  return httpClient
    .get(`${USER_API_URL}/form/client-information/${clientId}`)
    .then((resp) => {
      return resp.data ?? {};
    });
}

export function createClientFormData(formData: any): Promise<any> {
  return httpClient
    .post(`${USER_API_URL}/form/client-information`, formData)
    .then((resp) => {
      return resp.data ?? {};
    });
}

export function getSpouseFormData(clientId: string): Promise<SpouseForm> {
  return httpClient
    .get(`${USER_API_URL}/form/spouse-information/${clientId}`)
    .then((resp) => {
      return resp.data ?? {};
    });
}

export function createSpouseFormData(
  formData: SpouseForm
): Promise<SpouseForm> {
  return httpClient
    .post(`${USER_API_URL}/form/spouse-information`, formData)
    .then((resp) => {
      return resp.data ?? {};
    });
}

export function getMarriageFormData(clientId: string): Promise<MarriageForm> {
  return httpClient
    .get(`${USER_API_URL}/form/marriage/${clientId}`)
    .then((resp) => {
      return resp.data ?? {};
    });
}

export function createMarriageFormData(
  formData: MarriageForm
): Promise<MarriageForm> {
  return httpClient
    .post(`${USER_API_URL}/form/marriage`, formData)
    .then((resp) => {
      return resp.data ?? {};
    });
}

export function getFamilyFormData(clientId: string): Promise<FamilyForm> {
  return httpClient
    .get(`${USER_API_URL}/form/family-home/${clientId}`)
    .then((resp) => {
      return resp.data ?? {};
    });
}

export function createFamilyFormData(
  formData: FamilyForm
): Promise<FamilyForm> {
  return httpClient
    .post(`${USER_API_URL}/form/family-home`, formData)
    .then((resp) => {
      return resp.data ?? {};
    });
}

export function getAffidavitFormData(clientId: string): Promise<AffidavitForm> {
  return httpClient
    .get(`${USER_API_URL}/form/affidavit/${clientId}`)
    .then((resp) => {
      return resp.data ?? {};
    });
}

export function createAffidavitFormData(
  formData: AffidavitForm
): Promise<AffidavitForm> {
  return httpClient
    .post(`${USER_API_URL}/form/affidavit`, formData)
    .then((resp) => {
      return resp.data ?? {};
    });
}

export async function fetchAndSetFormData(
  setSelectedForm: (arg: any) => void,
  getFormData: (arg: any) => void,
  user: User
): Promise<void> {
  try {
    const clientData = await getFormData(user?.userId!);
    setSelectedForm(clientData);
  } catch (error) {
    console.error("Failed to fetch client data:", error);
  }
}

export function updateFormStatuses(
  data: CompleteForms
): Promise<AffidavitForm> {
  return httpClient.post(`${USER_API_URL}/form/statuses`, data).then((resp) => {
    return resp.data ?? {};
  });
}
